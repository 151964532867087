/* eslint-disable react/jsx-props-no-spreading */
// import { Component } from 'react';

import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useSelector } from 'react-redux'; // для импорта данных из стора через redux

import SelectCalcComponent from './SelectCalcComponent';
import OfferCompBasic from './OfferCompBasic';
import OfferCompExpert from './OfferCompExpert';
import OfferCompOptimal from './OfferCompOptimal';
import AfterOrderModalMessage from './AfterOrderModalMessage';
import ModalForOrder from './ModalForOrder';
import DataLinkFinal from '../DataLink/DataLinkFinal';

// const db = require('../DataBase/DataBase');

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function CalcPage() {
  // лог базы данных
  // console.log(dataGpsTable, 'готовый объект для работы');
  // useEffect(() => {
  //   const dataGpsTableMy = useSelector((state) => state.datas);
  // }, []);
  // const getTable = () => {
  //   const data = useSelector((state) => state.datas);
  //   return data;
  // };

  // const [dataGpsTableMy, setDataGpsTableMy] = useState(useSelector((state) => state.datas));
  // console.log(dataGpsTableMy);
  // const data = useSelector((state) => state.datas);
  // useEffect(() => {
  //   setDataGpsTableMy(useSelector((state) => state.datas));
  // }, []);
  // componentDidMount() {
  //   const dataGpsTableMy = useSelector((state) => state.datas);
  // };

  const dataGpsTableMy = useSelector((state) => state.datas);

  if (dataGpsTableMy.datas.length !== 0) {
    // console.log(typeof dataGpsTableMy.datas, 'длина моей таблички');
    // console.log(dataGpsTableMy.datas, 'моя табличка');
  }
  // ручка для всех компонентов-чекбоксов
  const [allCheckboxes, setAllCheckboxes] = useState({
    installTracker: 0, Rele: 0, motoHours: 0, fuelInstall: 0, fastInstall: 0,
  });

  const handleForAllCheckboxes = (event) => {
    if (event.target.checked) {
      // console.log(event, '<== это ивент');
      setAllCheckboxes((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    } else {
      setAllCheckboxes((prev) => ({ ...prev, [event.target.name]: 0 }));
    }
  };

  // стэйт и ручка для всех компонентов-выпадающих списков
  const [allSelects, setAllSelects] = useState({
    GpsTracker: { Price: 0 },
    Fuel: { Price: 0 },
    tempSensor: { Price: 0 },
    tiltSensor: { Price: 0 },
    complectsQuality: 1,
    abonService: { Price: 0 },
  });

  // стэйт для ресета селектов
  const [selectValue, setSelectValue] = useState({
    GpsTracker: '', Fuel: '', tempSensor: '', tiltSensor: '', complectsQuality: 1, abonService: '',
  });

  const handleForAllSelects = (event) => {
    // console.log(event);
    setAllSelects((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    // записывает выбранное значение в value select'а
    setSelectValue((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    // console.log(event.currentTarget, 'это таргет');
    // event.currentTarget.blur();
    // console.log(event.target.localName);
  };

  // стейт скинуть все чекбоксы
  const [checked, setChecked] = useState({
    fastIns: false, fuelIns: false, gpsIns: false, releIns: false, motoIns: false,
  });
    // console.log(checked, '<== это чект');

  // ручка для ресета чекбоксов и селектов
  const handlerReset = () => {
    setChecked({
      fastIns: false, fuelIns: false, gpsIns: false, releIns: false, motoIns: false,
    });
    setAllCheckboxes({
      installTracker: 0, Rele: 0, motoHours: 0, fuelInstall: 0, fastInstall: 0,
    });

    // сброс селектов в дефолт
    setSelectValue({
      GpsTracker: '', Fuel: '', tempSensor: '', tiltSensor: '', complectsQuality: 1, abonService: '',
    });
    setAllSelects({
      GpsTracker: { Price: 0 },
      Fuel: { Price: 0 },
      tempSensor: { Price: 0 },
      tiltSensor: { Price: 0 },
      complectsQuality: 1,
      abonService: { Price: 0 },
    });
  };
  // console.log(selectValue, 'selectValue');
  // console.log(allCheckboxes, 'checkboxes');

  // данные бота и чата для отправки в телегу
  const botToken = '6145145183:AAE9R3xOvNXWSQMT-5ZLmOA_MJrC-xZ-P2g';
  const chatId = '-1001826075920';
  // const botToken = '5358830966:AAGx475XYmMDV0tCLPhXHN_ZUAzpWzWn_Ro';
  // const chatId = '-1001857388319';

  // ручка для отправки сообщения из калькулятора
  // const handleSendMessage = () => {
  //   axios.post(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${JSON.stringify([selectValue, allCheckboxes])}`, [selectValue, allCheckboxes])
  //     .then((res) => console.log(res.data, '===это res==='));
  // };

  // состояние и ручка для модалки после отправки заказа
  const [open, setOpen] = React.useState(false);
  const handleOpenOrderModal = () => {
    setOpen(true);
    setTimeout(() => { setOpen(false); }, 3500);
  };
  const handleCloseOrderModal = () => setOpen(false);

  // общая цена
  const fullOrderCheck = (allSelects.GpsTracker.Price
  + (allCheckboxes.installTracker * 1)
  + (allCheckboxes.Rele * 1)
  + (allCheckboxes.motoHours * 1)
  + allSelects.Fuel.Price
  + (allCheckboxes.fuelInstall * 1)
  + allSelects.tempSensor.Price
  + allSelects.tiltSensor.Price
  + allSelects.abonService.Price
  + (allCheckboxes.fastInstall * 1))
  * allSelects.complectsQuality;

  if (dataGpsTableMy.datas.length !== 0) {
    return (
      <div
        id="oborudovanie-i-uslugi"
        style={{
          background: '#f7fbff',
          marginTop: '-17px',
          paddingTop: '70px',
          paddingBottom: '80px',
        }}
      >
        <Container>
          <div>
            <div style={{
              position: 'absolute',
              marginTop: '3px',
              width: '8px',
              height: '8px',
              background: '#FA541C',
              borderRadius: '100%',
            }}
            />
            <h4 style={{
              color: '#919eab',
              textAlign: 'left',
              fontWeight: 400,
              fontSize: '13px',
              marginLeft: '15px',
            }}
            >
              НАШИ УСЛУГИ И ОБОРУДОВАНИЕ
            </h4>
            <h1 style={{ color: '#2f2f2f', textAlign: 'left', fontSize: '45px' }}>Все для мониторинга транспорта</h1>
            <h4 style={{
              color: '#919eab',
              textAlign: 'left',
              fontWeight: 300,
              width: '70%',
            // fontStyle: 'italic',
            // lineHeight: '4px',
            // fontSize: '13px',
            }}
            >
              В этом разделе вы можете подобрать оборудование или тарифный план,
              который больше всего вам подходит.
              Для этого вы можете воспользоваться нашими готовыми предложениями
              или конструктором услуг
            </h4>
          </div>

          <AfterOrderModalMessage
            handleOpenOrderModal={handleOpenOrderModal}
            handleCloseOrderModal={handleCloseOrderModal}
            open={open}
          />

          <Box sx={{
            flexGrow: 1,
            // marginLeft: '0px',
            marginTop: '100px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          >
            <Grid
              container
              spacing={4}
              sx={{
                display: 'flex',
                // justifyContent: 'center',
                flexDirection: 'row',
                marginLeft: '0px',
                alignItems: 'center',
              // paddingLeft: 'none',
              // alignItems: 'baseline',
              }}
            >
              {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
              <Grid
                className="offers"
                item
                xs={12}
                md={4}
              >
                <Item className="offerCard">
                  <OfferCompBasic
                    dbOffer={dataGpsTableMy.datas.base}
                    botToken={botToken}
                    chatId={chatId}
                    handleOpenOrderModal={handleOpenOrderModal}
                  />
                </Item>
              </Grid>

              <Grid className="offers" item xs={12} md={4}>
                <Item className="offerCard">
                  <OfferCompOptimal
                    dbOffer={dataGpsTableMy.datas.optimal}
                    botToken={botToken}
                    chatId={chatId}
                    handleOpenOrderModal={handleOpenOrderModal}
                  />
                </Item>
              </Grid>

              <Grid className="offers" item xs={12} md={4}>
                <Item className="offerCard">
                  <OfferCompExpert
                    dbOffer={dataGpsTableMy.datas.business}
                    botToken={botToken}
                    chatId={chatId}
                    handleOpenOrderModal={handleOpenOrderModal}
                  />
                </Item>
              </Grid>
              {/* </div> */}

              {/* <Grid className="offers" item xs={10} md={4}>
              <Item>
                <OfferCompPremium />
              </Item>
            </Grid> */}

              <Grid id="monitoring-konstruktor" className="offers" item xs={12} md={12}>
                <div style={{ height: '90px' }} />
                <Item>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '-20px',
                    }}
                  >
                    {/* НОВАЯ ШАПКА КАЛЬКУЛЯТОРА */}
                    <Item style={{ boxShadow: 'none' }}>
                      <h2 style={{
                        textAlign: 'left', color: '#FA541C',
                      }}
                      >
                        <p className="pConstructor">Конструктор</p>
                        <p style={{
                          marginTop: '-20px',
                          fontSize: '16px',
                          fontWeight: 300,
                          color: '#919eab',
                        }}
                        >
                          Соберите свою конфигурацию оборудования
                        </p>

                      </h2>
                    </Item>
                    <Item style={{ boxShadow: 'none' }}>
                      <img
                        style={{ width: '80px' }}
                        src="icons/forFirstPage/constructorIconDark.png"
                        alt="Соберите свою конфигурацию оборудования"
                      />
                    </Item>
                  </Grid>

                  {/* GPS - ДАТЧИК ИЗ SELECT */}
                  {/* <SelectCalcComponent
                  selLabel="выберите GPS/ГЛОНАСС - трекер"
                  selectName="GpsTracker"
                  imgSrc="/icons/forCalcLittle/gps.png"
                  dbSelect={db.gpsTrackers}
                  selValue={selectValue.GpsTracker}
                  handleOnChange={handleForAllSelects}
                /> */}

                  {/* здесь div в котором все компоненты калькулятора */}
                  <div style={{
                  // background: 'red',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  >

                    {/* GPS - ДАТЧИК ИЗ SELECT */}
                    <SelectCalcComponent
                      selLabel="GPS/ГЛОНАСС - трекер"
                      selectName="GpsTracker"
                      imgSrc="/icons/forCalcLittle/gps.png"
                      dbSelect={dataGpsTableMy.datas.gpsTrackers}
                      selValue={selectValue.GpsTracker}
                      handleOnChange={handleForAllSelects}
                    />

                    {/* УСТАНОВКА GPS-ДАТЧИКА */}
                    <Box style={{
                      marginTop: '20px',
                      marginLeft: '3%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                    >
                      <img
                        style={{
                          height: '25px', width: '25px', borderRight: '8px solid white',
                        }}
                        src="/icons/forCalcLittle/install.png"
                        alt="установка ГЛОНАСС/GPS трекера"
                      />
                      <FormControlLabel
                        className="checkboxLabel"
                        label={dataGpsTableMy.datas.checkboxes[0].Name}
                        control={(
                          <Checkbox
                            onChange={(e) => {
                              // задает новое состояние в стэйте чекбокса
                              setChecked((prev) => ({ ...prev, gpsIns: e.target.checked }));
                              // записывает/удаляет значение value в объект с услугами
                              handleForAllCheckboxes(e);
                            }}
                            checked={checked.gpsIns}
                            value={dataGpsTableMy.datas.checkboxes[0].Price}
                            name="installTracker"
                          />
                      )}
                      />
                    </Box>

                    {/* РЕЛЕ БЛОКИРОВКИ */}
                    <Box style={{
                      marginLeft: '3%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                    >
                      <img
                        style={{
                          height: '20px', width: '20px', borderRight: '11px solid white', borderLeft: '2px solid white',
                        }}
                        src="/icons/forCalcLittle/blockEngine.png"
                        alt="реле блокировки двигателя"
                      />
                      <FormControlLabel
                        className="checkboxLabel"
                        label={dataGpsTableMy.datas.checkboxes[1].Name}
                        control={(
                          <Checkbox
                            onChange={(e) => {
                              // задает новое состояние в стэйте чекбокса
                              setChecked((prev) => ({ ...prev, releIns: e.target.checked }));
                              // записывает/удаляет значение value в объект с услугами
                              handleForAllCheckboxes(e);
                            }}
                            checked={checked.releIns}
                            value={dataGpsTableMy.datas.checkboxes[1].Price}
                            name="Rele"
                          />
                      )}
                      />
                    </Box>

                    {/* ДАТЧИК МОТОЧАСОВ */}
                    <Box style={{
                      marginLeft: '3%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                    >
                      <img
                        style={{
                          height: '25px', width: '25px', borderRight: '8px solid white',
                        }}
                        src="/icons/forCalcLittle/engineHours.png"
                        alt="реле блокировки двигателя"
                      />
                      <FormControlLabel
                        className="checkboxLabel"
                        label={dataGpsTableMy.datas.checkboxes[2].Name}
                        control={(
                          <Checkbox
                            onChange={(e) => {
                              // задает новое состояние в стэйте чекбокса
                              setChecked((prev) => ({ ...prev, motoIns: e.target.checked }));
                              // записывает/удаляет значение value в объект с услугами
                              handleForAllCheckboxes(e);
                            }}
                            checked={checked.motoIns}
                            value={dataGpsTableMy.datas.checkboxes[2].Price}
                            name="motoHours"
                          />
                      )}
                      />
                    </Box>

                    {/* ДАТЧИК УРОВНЯ ТОПЛИВА ИЗ SELECT */}
                    <SelectCalcComponent
                      selLabel="датчик уровня топлива"
                      selectName="Fuel"
                      imgSrc="/icons/forCalcLittle/fuelSensor.png"
                      dbSelect={dataGpsTableMy.datas.fuel}
                      selValue={selectValue.Fuel}
                      handleOnChange={handleForAllSelects}
                    />

                    {/* УСТАНОВКА ДАТЧИКА ТОПЛИВА */}
                    <Box style={{
                      marginTop: '20px',
                      marginLeft: '3%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                    >
                      <img
                        style={{
                          height: '25px', width: '25px', borderRight: '8px solid white',
                        }}
                        src="/icons/forCalcLittle/install.png"
                        alt="установка и тарировка датчика топлива"
                      />
                      <FormControlLabel
                        className="checkboxLabel"
                        label={dataGpsTableMy.datas.checkboxes[3].Name}
                        control={(
                          <Checkbox
                            onChange={(e) => {
                            // задает новое состояние в стэйте чекбокса
                              setChecked((prev) => ({ ...prev, fuelIns: e.target.checked }));
                              // записывает/удаляет значение value в объект с услугами
                              handleForAllCheckboxes(e);
                            }}
                            checked={checked.fuelIns}
                            value={dataGpsTableMy.datas.checkboxes[3].Price}
                            name="fuelInstall"
                          />
                      )}
                      />
                    </Box>

                    {/* НАЧАЛО ВТОРОГО БЛОКА ДЛЯ ПЕРЕНОСА */}

                    {/* ДАТЧИК ТЕМПЕРАТУРЫ ИЗ SELECT */}
                    <SelectCalcComponent
                      selLabel="температурный датчик"
                      selectName="tempSensor"
                      imgSrc="/icons/forCalcLittle/temperatureSensor.png"
                      dbSelect={dataGpsTableMy.datas.temperature}
                      selValue={selectValue.tempSensor}
                      handleOnChange={handleForAllSelects}
                    />

                    {/* ДАТЧИК УГЛА НАКЛОНА ИЗ SELECT */}
                    <SelectCalcComponent
                      selLabel="датчик угла наклона"
                      selectName="tiltSensor"
                      imgSrc="/icons/forCalcLittle/tiltAngle.png"
                      dbSelect={dataGpsTableMy.datas.angle}
                      selValue={selectValue.tiltSensor}
                      handleOnChange={handleForAllSelects}
                    />

                    {/* АБОНЕНТСКОЕ ОБСЛУЖИВАНИЕ ИЗ SELECT */}
                    <SelectCalcComponent
                      selLabel="абонентское обслуживание"
                      selectName="abonService"
                      imgSrc="/icons/forCalcLittle/abService.png"
                      dbSelect={dataGpsTableMy.datas.abService}
                      selValue={selectValue.abonService}
                      handleOnChange={handleForAllSelects}
                    />

                    <div style={{ display: 'flex' }}>
                      {/* КОЛИЧЕСТВО КОМПЛЕКТОВ ИЗ TextField */}
                      <TextField
                        sx={{ width: '40%', marginTop: '20px', marginRight: '20px' }}
                        inputProps={{
                          min: 1,
                          max: 99999,
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                          style: { textAlign: 'center', label: { fontWeight: 700 } },
                        }}
                        id="outlined-number"
                        className="complectsQuality"
                        name="complectsQuality"
                        label="ЧИСЛО КОМПЛЕКТОВ"
                        type="number"
                        value={selectValue.complectsQuality}
                      // defaultValue={1}
                        onChange={handleForAllSelects}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      {/* СРОЧНЫЙ МОНТАЖ ОБОРУДОВАНИЯ */}
                      <Box style={{
                        marginTop: '20px',
                        marginLeft: '3%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                      >
                        <img
                          style={{
                            height: '25px', width: '25px', borderRight: '8px solid white',
                          }}
                          src="/icons/forCalcLittle/fastInstall.png"
                          alt="срочный монтаж оборудования"
                        />
                        <FormControlLabel
                          className="checkboxLabel"
                          label={dataGpsTableMy.datas.checkboxes[4].Name}
                          control={(
                            <Checkbox
                              onChange={(e) => {
                              // задает новое состояние в стэйте чекбокса
                                setChecked((prev) => ({ ...prev, fastIns: e.target.checked }));
                                // записывает значение value в объект с услугами
                                handleForAllCheckboxes(e);
                              }}
                              checked={checked.fastIns}
                              value={dataGpsTableMy.datas.checkboxes[4].Price}
                              name="fastInstall"
                              id="fastInstall"
                            />
                      )}
                        />
                      </Box>
                    </div>

                    <hr style={{ width: '92%', marginTop: '20px', margin: '3% 2% 0 2%' }} />

                    <Item
                      className="offerSection"
                      sx={{
                        boxShadow: 'none',
                      // width: '97%',
                      // display: 'flex',
                      // alignItems: 'center',
                      // justifyContent: 'space-around',
                      // marginTop: '20px',
                      }}
                    >
                      <h2 style={{
                        fontWeight: 700, color: '#2f2f2f',
                      }}
                      >
                        <span style={{ fontWeight: 400, color: '#5b5b5b' }}>
                          Сумма заказа:
                        </span>
                        {' '}
                        {fullOrderCheck}
                        {' '}
                        руб
                      </h2>
                      <ModalForOrder
                        botToken={botToken}
                        chatId={chatId}
                        fullOrderCheck={fullOrderCheck}
                        selectValue={selectValue}
                        allCheckboxes={allCheckboxes}
                        handlerReset={handlerReset}
                        handleOpenOrderModal={handleOpenOrderModal}
                      />

                    </Item>

                    {/* кнопка скинуть все чекбоксы и селекты */}
                    {/* <Button
                    value="resetCheckboxes"
                    onClick={handlerReset}
                  >
                    скинуть все чекбоксы и селекты
                  </Button> */}

                  </div>
                  {/* ЭТО КОНЕЦ div ДЛЯ КАЛЬКУЛЯТОРА */}

                </Item>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    );
  }
}

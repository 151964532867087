import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// модальное окно
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ModalAfterMessage from '../CalcPage/ModalAfterMessage';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function CallMe() {
  // данные бота и чата для отправки в телегу
  const botToken = '6145145183:AAE9R3xOvNXWSQMT-5ZLmOA_MJrC-xZ-P2g';
  const chatId = '-1001826075920';
  // const botToken = '5358830966:AAGx475XYmMDV0tCLPhXHN_ZUAzpWzWn_Ro';
  // const chatId = '-1001857388319';

  // ручки для модалки
  const [openCallMe, setOpenCallMe] = React.useState(false);

  const handleClickOpen = () => {
    setOpenCallMe(true);
  };

  const handleClose = () => {
    setOpenCallMe(false);
  };

  // состояние окон модалки

  const [input, setInput] = useState({
    complect: 'Обратный звонок',
    clientname: '',
    phone: '',
    clienttime: '',
  });

  // ручка для изменения состояния input при вводе данных
  const changeInputHandler = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // console.log(input);
  };

  // ручка для отправки сообщения
  const handleSendMessage = () => {
    axios.post(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${JSON.stringify(input)}`, input);
    //   .then((res) => console.log(res.data, '===это res==='));

    setInput({
      complect: 'Обратный звонок',
      clientname: '',
      phone: '',
      clienttime: '',
    });
  };

  // состояние и ручка для модалки после отправки заказа
  const [open, setOpen] = React.useState(false);
  const handleOpenOrderModal = () => {
    setOpen(true);
    setTimeout(() => { setOpen(false); }, 3500);
  };
  const handleCloseOrderModal = () => setOpen(false);

  return (
    <div>
      {/* <script defer type="text/javascript" src="js/data.js" /> */}

      <ModalAfterMessage
        handleOpenOrderModal={handleOpenOrderModal}
        handleCloseOrderModal={handleCloseOrderModal}
        open={open}
      />

      <Button
        onClick={handleClickOpen}
        sx={{
          position: 'fixed',
          transform: 'rotate(90deg)',
          right: '-67px',
          //   right: '-1px',
          //   top: '350px',
          bottom: '170px',
          zIndex: '1000',
          fontWeight: '300',
        //   height: '50px',
        }}
        className="callMeButton"
        // className="buttonOffer"
        variant="outlined"
      >
        Заказать звонок
      </Button>
      <Dialog open={openCallMe} onClose={handleClose}>
        <DialogActions>
          {/* <Button onClick={handleClose}>X</Button> */}
          <Button onClick={handleClose}>закрыть</Button>
        </DialogActions>
        <DialogTitle>Заказ обратного звонка</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Введите пожалуйста ваши контактные данные
          </DialogContentText>
          <TextField
              // autoFocus
            onChange={changeInputHandler}
            margin="dense"
            id="name"
            name="clientname"
            value={input.clientname}
            label="Ваше имя"
            type="text"
            fullWidth
          />
          <TextField
              // autoFocus
            onChange={changeInputHandler}
            margin="dense"
            id="name"
            name="phone"
            value={input.phone}
            label="Ваш номер телефона"
            type="tel"
            fullWidth
          />
          {/* <div style={{ display: 'block' }}>
            <span className="inputTimeText">перезвоните мне сегодня в</span>
            {' '}
            <input
              className="inputTime"
              onChange={changeInputHandler}
              name="clienttime"
              value={input.clienttime}
              type="time"
            />
          </div> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>X</Button> */}
          <Button
            className="buttonOffer"
            style={{
              width: '200px',
              justifyContent: 'center',
              margin: 'auto',
              marginBottom: '15px',
            }}
            onClick={() => {
              handleClose();
              handleSendMessage();
              handleOpenOrderModal();
            }}
          >
            ПЕРЕЗВОНИТЕ МНЕ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function Temperature() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      {/* контроль вождения */}

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                Как и зачем отслеживать температуру?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <h4 className="question-style">
                Датчик температуры, например Escort TH BLE, позволяет отслеживать температуру
                в режиме реального времени. Это может быть полезно во многих ситуациях, например:
              </h4>
              <span>
                <h4 className="question-style">
                  Хранение продуктов и медикаментов:
                </h4>
                Некоторые продукты и медикаменты
                должны храниться при определенной температуре. Датчики температуры
                могут помочь гарантировать, что эти условия соблюдаются, и предупредить,
                если температура выходит за пределы допустимого.
                <p />
                <h4 className="question-style">
                  Транспортировка:
                </h4>
                Транспортировка некоторых продуктов, таких как продукты
                питания и лекарства, также требует соблюдения определенных температурных режимов.
                Отслеживание температуры в реальном времени во время транспортировки может
                помочь гарантировать, что продукты не портятся.
                <p />
                <h4 className="question-style">
                  Комфорт в жилых помещениях:
                </h4>
                Датчики температуры также могут использоваться для
                поддержания комфортной температуры в жилых помещениях.
                <p />
                Некоторые датчики, например Escort TH BLE, оснащены Bluetooth-модулем,
                который позволяет передавать
                данные о температуре в реальном времени на мобильное устройство, где вы можете
                просматривать данные и получать уведомления, если температура выходит за пределы
                допустимого диапазона. Его компактный размер и возможность беспроводной передачи
                данных делают его удобным для использования как в домашних условиях,
                так и в коммерческих целях.
              </span>
              <p />
            </div>
          </div>
        </button>
      </div>
    </Container>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function Wialon() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                Что такое wialon и как к нему подключиться?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <h4 className="question-style">
                Wialon - это платформа мониторинга транспортных средств и
                управления автопарком, разработанная компанией Gurtam.
              </h4>
              <span>
                Она позволяет отслеживать расположение и передвижение автомобилей,
                получать информацию о состоянии транспорта, контролировать расход топлива
                и многое другое.
                <p />
                Для подключения к платформе Wialon вам необходимо выполнить следующие шаги:
                <h4 className="question-style">
                  1. Установите на своё транспортное средство
                  GPS/ГЛОНАСС - трекер и другие необходимые датчики
                </h4>
                <h4 className="question-style">
                  2. Создайте аккаунт на сайте
                  {' '}
                  <a href="https://wialon.com" target="_blank" rel="noreferrer">https://wialon.com</a>
                </h4>
                <h4 className="question-style">
                  3. Зарегистрируйте трекер своего транспортного средства в системе и
                  таким образом подключите ваше транспортное средство к Wialon,
                  следуя инструкциям от производителя оборудования.
                </h4>
                <h4 className="question-style">
                  4. Настройте параметры мониторинга транспортного средства в системе
                  Wialon с помощью встроенных инструментов.
                </h4>
                <p />
                Важно отметить, что подключение к Wialon может потребовать дополнительных
                знаний и навыков, поэтому лучше обратиться к специалистам,
                которые могут помочь вам с этим процессом, например к
                специалистам компании Геотэг.
              </span>
              <p />
            </div>
          </div>
        </button>
      </div>
    </Container>
  );
}

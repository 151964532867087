import { useEffect, useState } from 'react';

export default function ScrollText() {
  const [scrollPosition, setScrollPosition] = useState(1300);
  // const [scrollPosition, setScrollPosition] = useState(8000);
  // размер шрифта
  const [winWidthFont, setWinWidthFont] = useState(30);

  //   хук для изменения значения ширины
  const [width, setWidth] = useState(window.innerWidth);
  //   функция для изменения значения ширины
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };
  //   ручка для изменения значания ширины при изменении ширины :)
  useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    setWinWidthFont((1.4 / width) * 20000);
    // console.log(width);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });

  //   ручка для скролла
  const handleScroll = () => {
    // изменение положения прокрутки
    const position = window.pageYOffset;
    // console.log(position);

    // ширина окна
    // const pageW = window.outerWidth;
    // console.log(pageW);

    // размер шрифта!!!!!!! при 350 - 70px при 1000 - 30px нужно решение
    // setWinWidthFont((1.4 / pageW) * 20000);
    // скорость передвижения
    setScrollPosition(scrollPosition - (position * 2.4));
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{ background: '#f7fbff' }} className="ScrollText">
      {/* <p>
        lorem ipsum
        {' '}
        {scrollPosition}
      </p> */}
      <svg style={{ backgroundColor: 'none' }} width="100%" height="160px" viewBox="0 0 1098.72 89.55">
        <path id="curve" fill="transparent" d="M0.17,0.23c0,0,105.85,77.7,276.46,73.2s243.8-61.37,408.77-54.05c172.09,7.64,213.4,92.34,413.28,64.19" />
        <text
          width="100%"
          style={{
            fontSize: `${winWidthFont}px`,
            fontWeight: '500',
            // zIndex: '900',
          }}
        >
          <textPath
            style={{ fill: 'cornflowerblue' }}
            alignmentBaseline="top"
            xlinkHref="#curve"
            startOffset={scrollPosition}
            // startOffset="100px"
            id="text-path"
          >
            🚗 ПРОЙДИТЕ ОПРОС ДЛЯ РАСЧЕТА СТОИМОСТИ!
            {/* {'  '}
            НЕ ВОЛНУЙТЕСЬ ЗА СВОЮ МАШИНУ!
            {'  '}
            НЕ ВОЛНУЙТЕСЬ ЗА СВОЮ МАШИНУ! */}
          </textPath>
        </text>
      </svg>

      {/* <svg
      style={{ transform: 'translateY(-90px)' }}
      width="100%"
      height="160px"
      viewBox="0 0 1098.72 89.55">
        <path
        id="curve"
        fill="transparent"
        d="M0.17,0.23c0,0,105.85,77.7,276.46,
        73.2s243.8-61.37,408.77-54.05c172.09,7.64,213.4,92.34,413.28,64.19" />
        <text
          width="100%"
          style={{
            fontSize: `${winWidthFont}px`,
            fontWeight: '500',
            // zIndex: '1000',
          }}
        >
          <textPath
            // style={{ transform: 'translate3d(0,0,0)' }}
            style={{ fill: 'cornflowerblue' }}
            alignmentBaseline="top"
            xlinkHref="#curve"
            startOffset={scrollPosition + 300}
            // startOffset="100px"
            id="text-path"
          >
            НЕ ВОЛНУЙТЕСЬ ЗА СВОЮ МАШИНУ!
          </textPath>
        </text>
      </svg> */}

      {/* <svg
      style={{ transform: 'translateY(-180px)' }}
      width="100%" height="160px"
      viewBox="0 0 1098.72 89.55">
        <path
        id="curve"
        fill="transparent" d="M0.17,0.23c0,0,105.85,77.7,
        276.46,73.2s243.8-61.37,408.77-54.05c172.09,7.64,213.4,92.34,413.28,64.19" />
        <text
          width="100%"
          style={{
            fontSize: `${winWidthFont}px`,
            fontWeight: '500',
            // zIndex: '1000',
          }}
        >
          <textPath
            // style={{ transform: 'translate3d(0,0,0)' }}
            style={{ fill: 'cornflowerblue' }}
            alignmentBaseline="top"
            xlinkHref="#curve"
            startOffset={scrollPosition - 300}
            // startOffset="100px"
            id="text-path"
          >
            НЕ ВОЛНУЙТЕСЬ ЗА СВОЮ МАШИНУ!
          </textPath>
        </text>
      </svg> */}

    </div>
  );
}

import React, { useState } from 'react';
import axios from 'axios';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import emailjs from '@emailjs/browser';

import AfterOrderModalMessage from '../CalcPage/AfterOrderModalMessage';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Quiz() {
  const botToken = '6145145183:AAE9R3xOvNXWSQMT-5ZLmOA_MJrC-xZ-P2g';
  const chatId = '-1001826075920';
  // ручки для модалки
  const [openModal, setOpenModal] = React.useState(false);
  const handleClickOpen = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // состояние окон модалки
  const [input, setInput] = useState({
    order: 'Квиз',
    clientname: '',
    phone: '',
    email: '',
  });
    // ручка для изменения состояния input при вводе данных в модалке
  const changeInputHandler = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // console.log(input);
  };

  // поле для ввода в квизе
  const [inputQuestion, setInputQuestion] = useState({
    other: '',
  });
  const changeInputQuestionHandler = (e) => {
    setInputQuestion((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // console.log(inputQuestion);
  };

  // ручка для всех компонентов-чекбоксов
  const [allCheckboxes, setAllCheckboxes] = useState({
    Tracker: 0,
    Fuel: 0,
    DriverControl: 0,
    HiddenTracker: 0,
    TemperatureControl: 0,
    Angle: 0,
    TimeWorking: 0,
    Video: 0,
    Rnis: 0,
  });

  const handleForAllCheckboxes = (event) => {
    if (event.target.checked) {
      // console.log(event, '<== это ивент');
      setAllCheckboxes((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    //   console.log(allCheckboxes);
    } else {
      setAllCheckboxes((prev) => ({ ...prev, [event.target.name]: 0 }));
    //   console.log(allCheckboxes);
    }
  };

  // стейт скинуть все чекбоксы
  const [checked, setChecked] = useState({
    trackerChecked: false,
    fuelChecked: false,
    driverControlChecked: false,
    hiddenTrackerChecked: false,
    temperatureControlChecked: false,
    angleChecked: false,
    timeWorkingChecked: false,
    videoChecked: false,
    rnisChecked: false,
  });
    // console.log(checked, '<== это чект');

  // ручка для ресета чекбоксов и поля для ввода
  const handlerReset = () => {
    setChecked({
      trackerChecked: false,
      fuelChecked: false,
      driverControlChecked: false,
      hiddenTrackerChecked: false,
      temperatureControlChecked: false,
      angleChecked: false,
      timeWorkingChecked: false,
      videoChecked: false,
      rnisChecked: false,
    });
    setAllCheckboxes({
      Tracker: 0,
      Fuel: 0,
      DriverControl: 0,
      HiddenTracker: 0,
      TemperatureControl: 0,
      Angle: 0,
      TimeWorking: 0,
      Video: 0,
      Rnis: 0,
    });
    setInputQuestion({ other: '' });
  };

  // сборная переменная для отправки на почту
  const filledOrder = `
    ${allCheckboxes.Tracker}
    ${allCheckboxes.TimeWorking}
    ${allCheckboxes.TemperatureControl}
    ${allCheckboxes.Rnis}
    ${allCheckboxes.Video}
    ${allCheckboxes.HiddenTracker}
    ${allCheckboxes.Fuel}
    ${allCheckboxes.DriverControl}
    ${allCheckboxes.Angle}
    ${inputQuestion}
    `;
  const dataForSend = {
    Клиент: input,
    // Услуги: allCheckboxes,
    Заказ: filledOrder,
    Вопрос: inputQuestion,
  };
  // ручка для отправки сообщения
  const handleSendMessage = () => {
    console.log(dataForSend);
    axios.post(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${JSON.stringify(dataForSend)}`, dataForSend);
    // .then((res) => console.log(res.data, '===это res==='));

    emailjs.send('service_w8ca6gs', 'template_z25nm3k', dataForSend, 'RaaGLuvBhpi4HOu8Y')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    setInput({
      order: 'Квиз',
      clientname: '',
      phone: '',
      email: '',
    //   allPrice: dbOffer[0].Price,
      // allPrice: '5900',
    });
  };

  // состояние и ручка для модалки после отправки заказа
  const [open, setOpen] = React.useState(false);
  const handleOpenOrderModal = () => {
    setOpen(true);
    setTimeout(() => { setOpen(false); }, 3500);
  };
  const handleCloseOrderModal = () => setOpen(false);

  return (
    <div
      id="raschet-stoimosti"
      style={{ background: '#f7fbff', paddingTop: '70px', paddingBottom: '30px' }}
    >
      <Container>
        <div>
          <div style={{
            position: 'absolute',
            marginTop: '3px',
            width: '8px',
            height: '8px',
            background: '#FA541C',
            borderRadius: '100%',
          }}
          />
          <h4 style={{
            color: '#919eab',
            textAlign: 'left',
            fontWeight: 400,
            fontSize: '13px',
            marginLeft: '15px',
          }}
          >
            РАСЧЕТ СТОИМОСТИ
          </h4>
          <h1 style={{
            color: '#2f2f2f',
            textAlign: 'left',
            fontSize: '45px',
          }}
          >
            Выберите нужные вам услуги

          </h1>
          <h4 style={{
            color: '#919eab',
            textAlign: 'left',
            fontWeight: 300,
            width: '70%',
            // fontStyle: 'italic',
            // lineHeight: '4px',
            // fontSize: '13px',
          }}
          >
            В этом разделе вы можете выбрать именно те услуги, которые вам нужны.
            Получив вашу заявку мы немедленно свяжемся с вами и предоставим подробный расчет
            нескольких вариантов реализации ваших задач
          </h4>
        </div>
        <AfterOrderModalMessage
          handleOpenOrderModal={handleOpenOrderModal}
          handleCloseOrderModal={handleCloseOrderModal}
          open={open}
        />
        <Item sx={{ position: 'relative', paddingBottom: '30px', zIndex: 100 }}>

          {/* Трекер и топливо */}

          <Grid sx={{ display: { md: 'flex', sx: 'block' }, ml: '1rem' }}>
            {/* <Grid sx={{ display: { md: 'flex', sx: 'block' }, ml: '1rem' }}> */}
            <Box style={{
              marginLeft: '3%',
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            >
              <img
                style={{
                  height: '20px', width: '20px', borderRight: '11px solid white', borderLeft: '2px solid white',
                }}
                src="/icons/forCalcLittle/gps.png"
                alt="отслеживание местоположения"
              />
              <FormControlLabel
                className="checkboxLabel"
                label="контроль местоположения"
        //   label={dataGpsTableMy.datas.checkboxes[1].Name}
                control={(
                  <Checkbox
                    onChange={(e) => {
                    // задает новое состояние в стэйте чекбокса
                      setChecked((prev) => ({ ...prev, trackerChecked: e.target.checked }));
                      // записывает/удаляет значение value в объект с услугами
                      handleForAllCheckboxes(e);
                    //   console.log(dataForSend.Клиент.clientname);
                    }}
                    checked={checked.trackerChecked}
                    value="нужно отслеживать положение "
                    name="Tracker"
                  />
                      )}
              />
            </Box>
            <Box style={{
              marginLeft: '3%',
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            >
              <img
                style={{
                  height: '20px', width: '20px', borderRight: '11px solid white', borderLeft: '2px solid white',
                }}
                src="/icons/forCalcLittle/fuelSensor.png"
                alt="контроль топлива"
              />
              <FormControlLabel
                className="checkboxLabel"
                label="контроль топлива"
        //   label={dataGpsTableMy.datas.checkboxes[1].Name}
                control={(
                  <Checkbox
                    onChange={(e) => {
                    // задает новое состояние в стэйте чекбокса
                      setChecked((prev) => ({ ...prev, fuelChecked: e.target.checked }));
                      // записывает/удаляет значение value в объект с услугами
                      handleForAllCheckboxes(e);
                    }}
                    checked={checked.fuelChecked}
                    value="нужен контроль топлива "
                    name="Fuel"
                  />
                      )}
              />
            </Box>
          </Grid>

          {/* Скрытый маячок и контроль водителя */}

          <Grid sx={{ display: { md: 'flex', sx: 'block' }, ml: '1rem' }}>
            <Box style={{
              marginLeft: '3%',
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            >
              <img
                style={{
                  height: '20px', width: '20px', borderRight: '11px solid white', borderLeft: '2px solid white',
                }}
                src="/icons/forCalcLittle/eye.png"
                alt="нужен скрытый маячок"
              />
              <FormControlLabel
                className="checkboxLabel"
                label="нужен скрытый маячок"
        //   label={dataGpsTableMy.datas.checkboxes[1].Name}
                control={(
                  <Checkbox
                    onChange={(e) => {
                    // задает новое состояние в стэйте чекбокса
                      setChecked((prev) => ({ ...prev, hiddenTrackerChecked: e.target.checked }));
                      // записывает/удаляет значение value в объект с услугами
                      handleForAllCheckboxes(e);
                    }}
                    checked={checked.hiddenTrackerChecked}
                    value="нужен скрытый маячок "
                    name="HiddenTracker"
                  />
                      )}
              />
            </Box>
            <Box style={{
              marginLeft: '3%',
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            >
              <img
                style={{
                  height: '20px', width: '20px', borderRight: '11px solid white', borderLeft: '2px solid white',
                }}
                src="/icons/forCalcLittle/driver.png"
                alt="контроль стиля вождения"
              />
              <FormControlLabel
                className="checkboxLabel"
                label="контроль стиля вождения"
        //   label={dataGpsTableMy.datas.checkboxes[1].Name}
                control={(
                  <Checkbox
                    onChange={(e) => {
                    // задает новое состояние в стэйте чекбокса
                      setChecked((prev) => ({ ...prev, driverControlChecked: e.target.checked }));
                      // записывает/удаляет значение value в объект с услугами
                      handleForAllCheckboxes(e);
                    }}
                    checked={checked.driverControlChecked}
                    value="нужен контроль стиля вождения "
                    name="DriverControl"
                  />
                      )}
              />
            </Box>
          </Grid>

          {/* Угол наклона и температура */}

          <Grid sx={{ display: { md: 'flex', sx: 'block' }, ml: '1rem' }}>
            <Box style={{
              marginLeft: '3%',
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            >
              <img
                style={{
                  height: '20px', width: '20px', borderRight: '11px solid white', borderLeft: '2px solid white',
                }}
                src="/icons/forCalcLittle/tiltAngle.png"
                alt="нужен датчик угла наклона механизмов"
              />
              <FormControlLabel
                className="checkboxLabel"
                label="данные об угле наклона"
        //   label={dataGpsTableMy.datas.checkboxes[1].Name}
                control={(
                  <Checkbox
                    onChange={(e) => {
                    // задает новое состояние в стэйте чекбокса
                      setChecked((prev) => ({ ...prev, angleChecked: e.target.checked }));
                      // записывает/удаляет значение value в объект с услугами
                      handleForAllCheckboxes(e);
                    }}
                    checked={checked.angleChecked}
                    value="нужен датчик угла наклона "
                    name="Angle"
                  />
                      )}
              />
            </Box>
            <Box style={{
              marginLeft: '3%',
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            >
              <img
                style={{
                  height: '20px', width: '20px', borderRight: '11px solid white', borderLeft: '2px solid white',
                }}
                src="/icons/forCalcLittle/temperatureSensor.png"
                alt="отслеживание температуры"
              />
              <FormControlLabel
                className="checkboxLabel"
                label="отслеживание температуры"
        //   label={dataGpsTableMy.datas.checkboxes[1].Name}
                control={(
                  <Checkbox
                    onChange={(e) => {
                    // задает новое состояние в стэйте чекбокса
                      setChecked((prev) => ({
                        ...prev, temperatureControlChecked: e.target.checked,
                      }));
                      // записывает/удаляет значение value в объект с услугами
                      handleForAllCheckboxes(e);
                    }}
                    checked={checked.temperatureControlChecked}
                    value="отслеживание температуры "
                    name="TemperatureControl"
                  />
            )}
              />
            </Box>
          </Grid>

          {/* Время работы и установка */}

          <Grid sx={{ display: { md: 'flex', sx: 'block' }, ml: '1rem' }}>
            <Box style={{
              marginLeft: '3%',
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            >
              <img
                style={{
                  height: '20px', width: '20px', borderRight: '11px solid white', borderLeft: '2px solid white',
                }}
                src="/icons/forCalcLittle/engineHours.png"
                alt="время работы и блокировка"
              />
              <FormControlLabel
                className="checkboxLabel"
                label="время работы и блокировка"
        //   label={dataGpsTableMy.datas.checkboxes[1].Name}
                control={(
                  <Checkbox
                    onChange={(e) => {
                    // задает новое состояние в стэйте чекбокса
                      setChecked((prev) => ({ ...prev, timeWorkingChecked: e.target.checked }));
                      // записывает/удаляет значение value в объект с услугами
                      handleForAllCheckboxes(e);
                    }}
                    checked={checked.timeWorkingChecked}
                    value="контроль времени работы и блокировка "
                    name="TimeWorking"
                  />
            )}
              />
            </Box>
            <Box style={{
              marginLeft: '3%',
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            >
              <img
                style={{
                  height: '20px', width: '20px', borderRight: '11px solid white', borderLeft: '2px solid white',
                }}
                src="/icons/forCalcLittle/cctv.png"
                alt="нужно видеонаблюдение"
              />
              <FormControlLabel
                className="checkboxLabel"
                label="нужно видеонаблюдение"
        //   label={dataGpsTableMy.datas.checkboxes[1].Name}
                control={(
                  <Checkbox
                    onChange={(e) => {
                    // задает новое состояние в стэйте чекбокса
                      setChecked((prev) => ({ ...prev, videoChecked: e.target.checked }));
                      // записывает/удаляет значение value в объект с услугами
                      handleForAllCheckboxes(e);
                    }}
                    checked={checked.videoChecked}
                    value="нужно видеонаблюдение "
                    name="Video"
                  />
            )}
              />
            </Box>
          </Grid>

          {/* РНИС и поле для заполнения */}

          <Grid sx={{ display: { md: 'flex', sx: 'block' }, ml: '1rem' }}>
            <Box sx={{
              marginLeft: '3%',
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            >
              <img
                style={{
                  height: '20px', width: '20px', borderRight: '11px solid white', borderLeft: '2px solid white',
                }}
                src="/icons/forCalcLittle/rnis.png"
                alt="хочу подключиться к РНИС"
              />
              <FormControlLabel
                className="checkboxLabel"
                label="хочу подключиться к РНИС "
        //   label={dataGpsTableMy.datas.checkboxes[1].Name}
                control={(
                  <Checkbox
                    onChange={(e) => {
                    // задает новое состояние в стэйте чекбокса
                      setChecked((prev) => ({ ...prev, rnisChecked: e.target.checked }));
                      // записывает/удаляет значение value в объект с услугами
                      handleForAllCheckboxes(e);
                    }}
                    checked={checked.rnisChecked}
                    value="хочу подключиться к РНИС"
                    name="Rnis"
                  />
            )}
              />
            </Box>
            <Box style={{
              marginLeft: '3%',
              //   marginTop: '20px',
              width: '100%',
              display: 'flex',
              // justifyContent: 'center',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            >
              <TextField
              // autoFocus
                sx={{ width: '90%' }}
                onChange={changeInputQuestionHandler}
                margin="dense"
                id="nother"
                name="other"
                value={inputQuestion.other}
                label="Нужно что-то ещё? Напишите..."
                type="text"
                fullWidth
              />
            </Box>
            {/* <Box style={{
          marginLeft: '3%',
          marginTop: '20px',
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          flexDirection: 'row',
        }}
        >
          <img
            style={{
              height: '20px',
              width: '20px',
              borderRight: '11px solid white',
              borderLeft: '2px solid white',
            }}
            src="/icons/forCalcLittle/blockEngine.png"
            alt="Требуется установка оборудования"
          />
          <FormControlLabel
            className="checkboxLabel"
            label="Требуется установка оборудования"
        //   label={dataGpsTableMy.datas.checkboxes[1].Name}
            control={(
              <Checkbox
                onChange={(e) => {
                // задает новое состояние в стэйте чекбокса
                  setChecked((prev) => ({ ...prev, videoChecked: e.target.checked }));
                  // записывает/удаляет значение value в объект с услугами
                  handleForAllCheckboxes(e);
                }}
                checked={checked.videoChecked}
                value="Требуется установка оборудования"
                name="Video"
              />
            )}
          />
        </Box> */}
          </Grid>

          {/* Модалка */}

          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              // alignItems: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleClickOpen}
              style={{ width: '90%' }}
          // href="#"
              className="buttonOffer"
              variant="outlined"
            >
              Заказать расчет
            </Button>
            <Dialog open={openModal} onClose={handleCloseModal}>
              <DialogActions>
                {/* <Button onClick={handleCloseModal}>X</Button> */}
                <Button onClick={handleCloseModal}>закрыть</Button>
              </DialogActions>
              <DialogTitle>
                Заказ расчета стоимости
                {/* {dbOffer[0].Price} */}
                {' '}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Введите пожалуйста ваши контактные данные
                </DialogContentText>
                <TextField
              // autoFocus
                  onChange={changeInputHandler}
                  margin="dense"
                  id="name"
                  name="clientname"
                  value={input.clientname}
                  label="Ваше имя"
                  type="text"
                  fullWidth
                />
                <TextField
              // autoFocus
                  onChange={changeInputHandler}
                  margin="dense"
                  id="name"
                  name="phone"
                  value={input.phone}
                  label="Ваш номер телефона"
                  type="text"
                  fullWidth
                />
                <TextField
              // autoFocus
                  onChange={changeInputHandler}
                  margin="dense"
                  id="name"
                  name="email"
                  value={input.email}
                  label="Ваша электронная почта"
                  type="email"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                {/* <Button onClick={handleCloseModal}>X</Button> */}
                <Button
                  className="buttonOffer"
                  style={{
                    width: '300px',
                    justifyContent: 'center',
                    margin: 'auto',
                    marginBottom: '15px',
                  }}
                  onClick={() => {
                    handleCloseModal();
                    handleSendMessage();
                    handleOpenOrderModal();
                    handlerReset();
                  }}
                >
                  Отправить на расчет
                </Button>
              </DialogActions>
            </Dialog>

          </Grid>
        </Item>
      </Container>
    </div>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function App() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      {/* топливо */}

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                Как контролируется уровень топлива?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <span>
                Датчики уровня топлива обычно устанавливаются в бак автомобиля и измеряют
                уровень топлива в нем с помощью токового датчика. Они
                подключены к навигационному GPS/ГЛОНАСС трекеру, установленному в
                автомобиле и передают информацию о
                количестве топлива в любую мониторинговую платформу или на дисплей в кабину водителя
                для отслеживания топливопотребления.
              </span>
              <h4 className="question-style">
                Датчик Omnicomm использует специальный сенсор, который
                устанавливается внутри топливного бака.
              </h4>
              Этот сенсор
              измеряет уровень топлива с использованием метода ёмкостного измерения.
              Это означает, что датчик
              измеряет изменение электрической ёмкости между его плоскими поверхностями и
              топливом.
              <br />
              Такой датчик также может измерять температуру топлива и скорость его расхода,
              что может помочь в обнаружении утечек и других проблем в работе системы
              топливоподачи.
              <h4 className="question-style">
                А такой датчик уровня топлива как Escort измеряет уровень топлива в баке путем
                использования своего электронного модуля, который работает как
                магнитный датчик.
              </h4>
              Он может быть установлен
              на любом типе топливного бака и может измерять уровень топлива с высокой точностью.
              <h4 className="question-style">
                Обе эти системы контроля уровня топлива могут быть полезны в обнаружении
                проблем с топливоподачей, таких как утечки топлива и потери топлива
                через испарение.
                Они также могут быть использованы для оптимизации расхода топлива
                и уменьшения
                затрат на топливо в долгосрочной перспективе.
              </h4>
            </div>
          </div>
        </button>
      </div>

      {/* уровень топлива

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style">
                Как осуществляется конроль за уровнем топлива?
              </h3>
              <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              />
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <span>
                Для этого используются GPRS/ГЛОНАСС трекеры,
                передающие данные по местоположению в режиме реального времени.
                Они могут быть использованы для множества целей, таких как:

              </span>
              <h4 className="question-style">
                Отслеживание местонахождения транспортных средств:
              </h4>
              Датчики уровня топлива, такие как Omnicomm или Escort,
              работают на основе аналогичных принципов.
              Они обычно устанавливаются в бак для топлива и измеряют
              уровень топлива в нем с помощью токового датчика. Этот датчик
              подключен к контроллеру автомобиля и передает информацию о
              количестве топлива на панель приборов или к другому устройству
              для мониторинга топливопотребления.

              Датчик Omnicomm использует сенсор, который устанавливается внутри
              топливного бака. Этот сенсор
              измеряет уровень топлива с использованием метода ёмкостного измерения.
              Что означает, что датчик
              измеряет изменение электрической ёмкости между его плоскими поверхностями и
              топливом.
              Датчик Omnicomm также может измерять температуру топлива и скорость его расхода,
              что может помочь в обнаружении утечек и других проблем в работе системы топливоподачи.

              Датчик уровня топлива Escort также измеряет уровень топлива в баке путем
              использования своего лектронного модуля, который работает как
              магнитный датчик.
              Датчик Escort может быть установлен
              на любом типе топливного бака и может измерять уровень топлива с высокой точностью.

              Обе эти системы контроля уровня топлива могут быть полезны в обнаружении
              проблем с топливоподачей, таких как утечки топлива и потери топлива через испарение.
              Они также могут быть использованы для оптимизации расхода топлива и уменьшения
              затрат на топливо в долгосрочной перспективе.

            </div>
          </div>
        </button>
      </div> */}
    </Container>
  );
}

import React from 'react';
import FirstPage from './components/FirstPage/FirstPage';
import NavBar from './components/NavBar/NavBar';
import CalcPage from './components/CalcPage/CalcPage';
import WhyPage from './components/WhyPage/WhyPage';
import WhoWeAre from './components/WhoWeAre/WhoWeAre';
import Contacts from './components/Contacts/Contacts';
import Footer from './components/Footer/Footer';
import CallMe from './components/CallMe/CallMe';
import ScrollText from './components/ScrollText/ScrollText';
import Quiz from './components/Quiz/Quiz';
import Accordeon from './components/FAQAccordeon/Accordeon';
// import DataLinkFinal from './components/DataLink/DataLinkFinal';
import DataLinkContext from './components/DataLink/DataLinkContext';
// import TestReduxData from './components/DataLink/TestReduxData';
// import NewMapYandex from './components/Contacts/NewMapYandex';

export default function App() {
  return (
    <div>
      <NavBar />
      <FirstPage />
      {/* <DataLinkFinal /> */}
      <DataLinkContext />
      {/* <TestReduxData /> */}
      <CallMe />
      <ScrollText />
      <Quiz />
      <Accordeon />
      <CalcPage />
      <WhyPage />
      <WhoWeAre />
      {/* <NewMapYandex /> */}
      <Contacts />
      <Footer />
    </div>
  );
}

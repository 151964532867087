import React, { useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// модальное окно
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// отправка на почту
import emailjs from '@emailjs/browser';

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

export default function ModalForOrder({
  botToken,
  chatId,
  fullOrderCheck,
  selectValue,
  allCheckboxes,
  handlerReset,
  handleOpenOrderModal,
  // handleSendMessage,
}) {
  // данные бота и чата для отправки в телегу
  // const botToken = '5358830966:AAGx475XYmMDV0tCLPhXHN_ZUAzpWzWn_Ro';
  // const chatId = '-1001857388319';

  // ручки для модалки
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // состояние окон модалки

  const [input, setInput] = useState({
    complect: 'Калькулятор',
    clientname: '',
    phone: '',
    email: '',
  });

  // ручка для изменения состояния input при вводе данных
  const changeInputHandler = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // console.log(input);
  };

  // переменные для "расшифровки" заказа
  // const clientCalcOrder = {
  //   Заказ: input.complect,
  //   Имя: input.clientname,
  //   Телефон: input.phone,
  //   Почта: input.email,
  //   Трекер: selectValue.GpsTracker.Name,
  //   Датчик_топлива: selectValue.Fuel.Name,
  //   Датчик_температуры: selectValue.tempSensor.Name,
  //   Датчик_наклона: selectValue.tiltSensor.Name,
  //   Количество_комплектов: selectValue.complectsQuality,
  //   Абонентское_обслуживание: selectValue.abonService.Name,
  //   Установка_трекера: allCheckboxes.installTracker,
  //   Реле_блокировки: allCheckboxes.Rele,
  //   Датчик_моточасов: allCheckboxes.motoHours,
  //   Установка_дат_топлива: allCheckboxes.fuelInstall,
  //   Срочная_установка: allCheckboxes.fastInstall,
  //   Общая_сумма: fullOrderCheck,
  // };

  // новые переменные для "расшифровки" заказа
  const clientCalcOrder = `
    ${!selectValue.GpsTracker.Name || selectValue.GpsTracker.Name === 'выберите GPS/ГЛОНАСС - трекер' ? ('') : (`трекер: ${selectValue.GpsTracker.Name}, `)}`
    + `${!selectValue.Fuel.Name || selectValue.Fuel.Name === 'выберите датчик уровня топлива' ? ('') : (`датчик топлива: ${selectValue.Fuel.Name}, `)}`
    + `${!selectValue.tempSensor.Name || selectValue.tempSensor.Name === 'выберите температурный датчик' ? ('') : (`датчик температуры: ${selectValue.tempSensor.Name}, `)}`
    + `${!selectValue.tiltSensor.Name || selectValue.tiltSensor.Name === 'выберите датчик угла наклона' ? ('') : (`датчик наклона: ${selectValue.tiltSensor.Name}, `)}`
    + `${!selectValue.abonService.Name || selectValue.abonService.Name === 'выберите абонентское обслуживание' ? ('') : (`абонентское обслуживание: ${selectValue.abonService.Name}, `)}`
    + `${!allCheckboxes.installTracker ? ('') : (`установка трекера (${allCheckboxes.installTracker} руб), `)}`
    + `${!allCheckboxes.Rele ? ('') : (`реле блокировки двигателя (${allCheckboxes.Rele} руб), `)}`
    + `${!allCheckboxes.motoHours ? ('') : (`датчик моточасов (${allCheckboxes.motoHours} руб), `)}`
    + `${!allCheckboxes.fuelInstall ? ('') : (`установка датчика топлива (${allCheckboxes.fuelInstall} руб), `)}`
    + `${!allCheckboxes.fastInstall ? ('') : (`срочный монтаж оборудования (${allCheckboxes.fastInstall} руб), `)}`
    + `${`количество комплектов: ${selectValue.complectsQuality}`}`;

  const calcOrder = `
  Откуда заказ: ${input.complect}, `
  + `Имя: ${input.clientname}, `
  + `Телефон: ${input.phone}, `
  + `Почта: ${input.email}, `
  + `Что заказали: ${clientCalcOrder}, `
  + `Общая сумма: ${fullOrderCheck} руб.`;

  const calcOrderForMail = {
    orderFrom: input.complect,
    clientname: input.clientname,
    phone: input.phone,
    email: input.email,
    order: clientCalcOrder,
    allPrice: fullOrderCheck,
  };

  // console.log(clientCalcOrder);
  // ручка для отправки сообщения в телегу и на мыло
  const handleSendMessageCalc = () => {
    // отправка в телегу
    axios.post(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${JSON.stringify(calcOrder)}`, calcOrder);
    // .then((res) => console.log(res.data, '===это res==='));

    // отправка на мыло клиенту
    // emailjs.send('service_wg4r688', 'template_ejyizqd', calcOrderForMail, 'RaaGLuvBhpi4HOu8Y')
    emailjs.send('service_w8ca6gs', 'template_ejyizqd', calcOrderForMail, 'RaaGLuvBhpi4HOu8Y')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    setInput({
      complect: 'Калькулятор',
      clientname: '',
      phone: '',
      email: '',
    });
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{ width: '200px' }}
          // href="#"
        className="buttonOffer"
        variant="outlined"
      >
        Заказать
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{
          transform: 'translateX(-5vw)', width: '110vw', margin: '0',
        }}
      >
        <DialogActions>
          {/* <Button onClick={handleClose}>X</Button> */}
          <Button onClick={handleClose}>закрыть</Button>
        </DialogActions>
        <DialogTitle>
          Оформить заказ на сумму
          {' '}
          {fullOrderCheck}
          {' '}
          руб.
          {/* <span style={{ display: 'block', fontSize: '15px', fontWeight: '400' }}>
            {clientCalcOrder}
          </span> */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span style={{
              display: 'block', fontSize: '15px', fontWeight: '400', paddingBottom: '20px',
            }}
            >
              {clientCalcOrder}
            </span>
            <span style={{ color: 'black' }}>
              Введите пожалуйста ваши контактные данные
            </span>
          </DialogContentText>
          <TextField
              // autoFocus
            onChange={changeInputHandler}
            margin="dense"
            id="nclientame"
            name="clientname"
            value={input.clientname}
            label="Ваше имя"
            type="text"
            fullWidth
          />
          <TextField
              // autoFocus
            onChange={changeInputHandler}
            margin="dense"
            id="nclientame"
            name="phone"
            value={input.phone}
            label="Ваш номер телефона"
            type="text"
            fullWidth
          />
          <TextField
              // autoFocus
            onChange={changeInputHandler}
            margin="dense"
            id="nclientame"
            name="email"
            value={input.email}
            label="Ваша электронная почта"
            type="email"
            fullWidth
          />
          <DialogActions>
            {/* <Button onClick={handleClose}>X</Button> */}
            <Button
              className="buttonOffer"
              style={{
                width: '200px',
                justifyContent: 'center',
                margin: 'auto',
                marginBottom: '15px',
              }}
              onClick={() => {
                handleClose();
                handleSendMessageCalc();
                handleOpenOrderModal();
                handlerReset();
              }}
            >
              ОФОРМИТЬ ЗАКАЗ

            </Button>
          </DialogActions>
        </DialogContent>

        {/* <DialogActions>
          <Button
            className="buttonOffer"
            style={{
              width: '200px',
              justifyContent: 'center',
              margin: 'auto',
              marginBottom: '15px',
            }}
            onClick={() => {
              handleClose();
              handleSendMessageCalc();
              handleOpenOrderModal();
              handlerReset();
            }}
          >
            ОФОРМИТЬ ЗАКАЗ

          </Button>
        </DialogActions> */}

      </Dialog>
    </div>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function WhatMonitoring() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      {/* контроль вождения */}

      <div className="faq">
        <button
          // style={{ width: '100%' }}
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                Что такое мониторинг транспорта и как он работает?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <h4 className="question-style">
                Мониторинг транспорта - это процесс отслеживания местоположения
                и движения транспортных средств с использованием различных технологий,
                таких как GPS, ГЛОНАСС, мобильные сети и другие.
              </h4>
              <span>
                Информация с GPS и ГЛОНАСС трекеров и других устройств
                передается в реальном времени на удаленный сервер,
                где ее можно визуализировать на карте и анализировать для управления
                транспортным парком.
                <p />
                Основная цель мониторинга транспорта - это контроль
                эксплуатации транспортных средств, их более
                эффективной работы и увеличение безопасности
                движения.
                <br />
                С помощью систем мониторинга можно получить различную
                информацию о транспорте, такую как текущее местоположение,
                скорость, расход топлива, пробег, перерывы на отдых и т.д.
                <h4 className="question-style">
                  Эта информация позволяет оптимизировать маршруты, контролировать
                  соблюдение режимов работы водителей, улучшать качество обслуживания и др.
                </h4>

                Работа системы мониторинга транспорта состоит из следующих шагов:
                <p />
                <h4 className="question-style">
                  1. Установка мониторингового оборудования на транспортное средство.
                </h4>
                <h4 className="question-style">
                  2. Сбор данных о местоположении и движении транспорта с помощью GPS,
                  ГЛОНАСС или других технологий.
                </h4>
                <h4 className="question-style">
                  3. Передача данных на удаленный сервер в реальном времени с
                  использованием мобильной сети или сети Интернет.
                </h4>
                <h4 className="question-style">
                  4. Обработка и визуализация данных на карте с помощью специального
                  программного обеспечения.
                </h4>
                <h4 className="question-style">
                  5. Анализ данных для принятия управленческих решений и улучшения
                  эффективности транспортного парка.
                </h4>

                Мониторинг транспорта является важным инструментом управления
                транспортными средствами для различных отраслей, таких как логистика,
                перевозки, грузоперевозки, пассажирские перевозки, строительство и другие.
              </span>
              <p />
            </div>
          </div>
        </button>
      </div>
    </Container>
  );
}

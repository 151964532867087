import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function WhatTransport() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                На какие транспортные средства можно установить систему мониторинга транспорта?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <h4 className="question-style">
                Системы мониторинга транспорта могут быть установлены на
                различные транспортные средства, в том числе:
              </h4>
              <span>
                <h4 className="question-style">
                  Автомобили:
                </h4>
                легковые автомобили, грузовики, автобусы и такси.
                <br />
                <h4 className="question-style">
                  Мотоциклы:
                </h4>
                двухколесные мотоциклы или трициклы.
                <br />
                <h4 className="question-style">
                  Водный транспорт:
                </h4>
                яхты, катера, суда для перевозки пассажиров и грузов.
                <br />
                <h4 className="question-style">
                  Воздушный транспорт:
                </h4>
                самолеты, вертолеты, дирижабли и другие
                виды воздушного транспорта.
                <br />
                <h4 className="question-style">
                  Железнодорожный транспорт:
                </h4>
                локомотивы, грузовые и пассажирские вагоны.
                <br />
                <h4 className="question-style">
                  Велосипеды и самокаты:
                </h4>
                горные и шоссейные велосипеды, прокатные велосипеды и самокаты.
                <p />
                Также существуют системы мониторинга, которые могут быть установлены
                на специальную технику, такую как автокраны, экскаваторы,
                бульдозеры и другая строительная и сельскохозяйственная техника.
                <p />
                В основном, любой транспортный (и любой другой) объект, который можно отслеживать с
                помощью GPS-сигнала, может быть использован в качестве объекта мониторинга.
              </span>
              <p />
            </div>
          </div>
        </button>
      </div>
    </Container>
  );
}

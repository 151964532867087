import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// const pages = ['Оборудование и услуги', 'О нас', 'Контакты'];
const pages = [{
  name: 'Оборудование и услуги',
  link: '#oborudovanie-i-uslugi',
},
{
  name: 'Конструктор',
  link: '#monitoring-konstruktor',
},
{
  name: 'О нас',
  link: '#o-nas',
},
{
  name: 'Контакты',
  link: '#contacts',
}];

// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Footer() {
  return (
    <div style={{
      backgroundColor: '#161722', minHeight: '75px', boxShadow: 'none', padding: '50px 0 40px 0 ',
    }}
    >
      <Container maxWidth="lg" sx={{}}>
        <hr style={{ color: '#919eab' }} />

        {/* <Avatar
            alt="Геотэг"
            style={{
              height: '70px', width: '60px', background: 'none', margin: 'auto',
            }}
          >
            <img src="/icons/logoGeotagPng.png" alt="" style={{ height: '65px' }} />
          </Avatar> */}
        {/* <hr style={{ color: 'white' }} /> */}

        {/* грид для всех секций */}
        <Grid
          sx={{
            width: '100%',
            display: 'flex',
            mt: 4,
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
            justifyContent: { xs: 'start', sm: 'start', md: 'space-between' },
            alignItems: { xs: 'center', sm: 'center', md: 'baseline' },
          }}
        >
          {/* секция 1 */}
          <Grid xs={12} sm={12} md={3}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#navbar"
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 300,
                letterSpacing: '.1rem',
                color: 'white',
                textDecoration: 'none',
                paddingTop: '20px',
                lineHeight: '15px',
                //   textAlign: 'center',
              }}
            >
              ООО ГЕОТЭГ 2023
            </Typography>
          </Grid>

          {/* секция 2 */}
          <Grid xs={12} sm={12} md={6}>
            <Box className="footerMenu">
              {pages.map((page) => (
                <Button
                  key={page.name}
                  sx={{ background: 'none' }}
                >
                  <a
                    className="navMenuFooter"
                    style={{
                    //   color: 'white',
                      textDecoration: 'none',
                      fontWeight: '300',
                      marginRight: '5px',
                      fontSize: '12px',
                    }}
                    href={page.link}
                  >
                    {page.name}

                  </a>
                </Button>
              ))}
            </Box>
          </Grid>
          {/* секция 3 */}
          <Grid xs={12} sm={12} md={3}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="tel:+74993917374"
              sx={{
                mr: 2,
                mt: 3,
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: '15px',
                letterSpacing: '.04rem',
                color: 'white',
                textDecoration: 'none',
                paddingTop: '10px',
                lineHeight: '15px',
                textAlign: 'right',
              }}
            >
              +7 499 391 73 74
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="mailto:geotaggps@gmail.com"
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 300,
                fontSize: '15px',
                letterSpacing: '.04rem',
                color: 'white',
                textDecoration: 'none',
                paddingTop: '3px',
                lineHeight: '17px',
                textAlign: 'right',
              }}
            >
              sale@geotag.pro
            </Typography>
          </Grid>
        </Grid>

      </Container>
    </div>
  );
}
export default Footer;

// import React from 'react';
// import { Box, Typography, Link } from '@mui/material';
// // import useStyles from './styles';

// function Footer() {
//   const date = new Date().getFullYear();
//   //   const classes = useStyles();

//   return (
//     <Box sx={{ flexGrow: 1 }} className="footerContainer">
//       <Typography className="footerText">
//         Provided by
//         {' '}
//         <Link href="https://appseed.us" target="_blank" underline="none">
//           AppSeed
//         </Link>
//       </Typography>
//       <Typography className="footerDate">Open-Source Sample - Buit with MUI</Typography>
//     </Box>
//   );
// }

// export default Footer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { read, utils } from 'xlsx';

const url = 'db/adminTable.xlsx';

export const fetchTable = createAsyncThunk(
  'datas/fetchTable',
  async () => {
    const myTable = {};
    const wb = read(await (await fetch(url, { cache: 'no-store' })).arrayBuffer(), { WTF: 1 });
    const myData = wb.SheetNames.forEach((i) => {
      const data = utils.sheet_to_json(wb.Sheets[i], { header: 1 });
      myTable[i] = data.map((el) => (
        {
          Name: el[0],
          Text: el[1],
          Price: el[2],
          Image: el[3],
        }
      ));
    });
    return myTable;
  },
);

const dataSlice = createSlice({
  name: 'datas',
  initialState: { // начальное состояние
    datas: [],
    status: null,
    error: null,
  },
  reducers: {
    getData(state, action) {
      state.datas.push({
        dataGpsTable: action.payload.dataGpsTable,
      });

    //   console.log(state, 'это стэйт из слайса');
    //   console.log(action, 'это экшн из слайса');
    }, // метод который нам нужен, этот получает данные
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTable.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    }); // во время загрузки, можно сделать прелоадер
    builder.addCase(fetchTable.fulfilled, (state, action) => {
      state.status = 'resolved'; // говорим что данные успешно загружены
      state.datas = action.payload; // кладем пришедшие данные в state.datas
    });
    builder.addCase(fetchTable.rejected, (state, action) => {}); // произошла неполадка
  },
});

export const { getData } = dataSlice.actions; // этот экшн мы подключим в компонент
export default dataSlice.reducer; // этот редюсер мы подключим в стор

import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function Video() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                Как и зачем устанавливать видеонаблюдение на транспортное средство?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <h4 className="question-style">
                Установка видеонаблюдения в автомобиль может иметь несколько целей:
              </h4>
              <span>
                <h4 className="question-style">
                  Безопасность:
                </h4>
                Видеонаблюдение может помочь защитить автомобиль от кражи или вандализма,
                а также обеспечить безопасность водителя и пассажиров в случае аварии или нападения.
                <p />
                <h4 className="question-style">
                  Доказательства:
                </h4>
                Видео-записи могут использоваться в качестве
                доказательства при ДТП или конфликтах на дороге. В некоторых случаях
                это может сократить время, затраченное на разрешение споров и улучшить
                исход судебного разбирательства.
                <p />
                <h4 className="question-style">
                  Обучение водителей:
                </h4>
                Видео-записи могут быть использованы для обучения водителей, помочь им
                увидеть свои ошибки и улучшить свое вождение.
                <p />
                Установка видеонаблюдения может быть осуществлена как профессионалами в
                автомобильном сервисе, так и самостоятельно при наличии соответствующих
                навыков и инструментов.
                В зависимости от выбора, могут использоваться
                различные видео-камеры, мониторы и дополнительное оборудование.
                <p />
                Однако, стоит учитывать, что в некоторых странах законодательство может иметь
                ограничения на использование видеонаблюдения в автомобиле. Поэтому, перед установкой
                видеокамеры, рекомендуется изучить местные законы и требования.
              </span>
              <p />
            </div>
          </div>
        </button>
      </div>
    </Container>
  );
}

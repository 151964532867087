import React, { useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// модальное окно
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// отправка на почту
import emailjs from '@emailjs/browser';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function OfferCompBasic({
  dbOffer, botToken, chatId, handleOpenOrderModal,
}) {
  // данные бота и чата для отправки в телегу
  // const botToken = '5358830966:AAGx475XYmMDV0tCLPhXHN_ZUAzpWzWn_Ro';
  // const chatId = '-1001857388319';

  // ручки для модалки
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // состояние окон модалки

  const [input, setInput] = useState({
    order: 'комплект "Базовый"',
    clientname: '',
    phone: '',
    email: '',
    allPrice: dbOffer[0].Price,
    // allPrice: '5900',
  });

  // ручка для изменения состояния input при вводе данных
  const changeInputHandler = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // console.log(input);
  };

  // ручка для отправки сообщения
  const handleSendMessage = () => {
    axios.post(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${JSON.stringify(input)}`, input);
    // .then((res) => console.log(res.data, '===это res==='));

    emailjs.send('service_w8ca6gs', 'template_ejyizqd', input, 'RaaGLuvBhpi4HOu8Y')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    setInput({
      order: 'комплект "Базовый"',
      clientname: '',
      phone: '',
      email: '',
      allPrice: dbOffer[0].Price,
      // allPrice: '5900',
    });
  };

  return (
    <div>
      <Grid
        className="orderBlock"
        item
        xs={12}
        md={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Item sx={{ boxShadow: 'none' }}>
          <h2 style={{
            textAlign: 'left', color: '#FA541C',
          }}
          >
            <p>Базовый</p>
            <p style={{ color: '#2f2f2f', fontSize: '30px', fontWeight: 600 }}>
              {dbOffer[0].Price}
              {' '}
              ₽
            </p>

          </h2>
        </Item>
        <Item sx={{ boxShadow: 'none' }}>
          <img
            style={{ height: '80px', left: '40px' }}
            src="icons/forFirstPage/econom.svg"
            alt="Комплект для мониторинга начального уровня"
          />
        </Item>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          // alignItems: 'center',
          alignItems: 'flex-start',
        }}
      >
        <p
          className="nameOfOffer"
        >
          Комплект начального уровня
        </p>
        {dbOffer.map((el, i) => (
          <div key={el.Name} className="divForTextWithBird">
            <Item sx={{ boxShadow: 'none' }}>
              <span className="material-symbols-outlined bird">
                done
              </span>
            </Item>
            <Item className="textWithBird" sx={{ boxShadow: 'none' }}>
              <p>
                {dbOffer[i].Name}
                {/* Бюджетный GPS/ГЛОНАСС - трекер с установкой */}
              </p>
            </Item>
          </div>
        ))}

      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          // alignItems: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          onClick={handleClickOpen}
          // style={{ background: '#f0682e' }}
          // href="#"
          className="buttonOffer"
          variant="outlined"
        >
          Заказать
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogActions>
            {/* <Button onClick={handleClose}>X</Button> */}
            <Button onClick={handleClose}>закрыть</Button>
          </DialogActions>
          <DialogTitle>
            Заказать базовый комплект (
            {dbOffer[0].Price}
            {' '}
            ₽)
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Введите пожалуйста ваши контактные данные
            </DialogContentText>
            <TextField
              // autoFocus
              onChange={changeInputHandler}
              margin="dense"
              id="name"
              name="clientname"
              value={input.clientname}
              label="Ваше имя"
              type="text"
              fullWidth
              // variant="standard"
            />
            <TextField
              // autoFocus
              onChange={changeInputHandler}
              margin="dense"
              id="name"
              name="phone"
              value={input.phone}
              label="Ваш номер телефона"
              type="text"
              fullWidth
              // variant="standard"
            />
            <TextField
              // autoFocus
              onChange={changeInputHandler}
              margin="dense"
              id="name"
              name="email"
              value={input.email}
              label="Ваша электронная почта"
              type="email"
              fullWidth
              // variant="standard"
            />
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>X</Button> */}
            <Button
              className="buttonOffer"
              style={{
                width: '200px',
                justifyContent: 'center',
                margin: 'auto',
                marginBottom: '15px',
              }}
              onClick={() => {
                handleClose();
                handleSendMessage();
                handleOpenOrderModal();
              }}
            >
              ОФОРМИТЬ ЗАКАЗ
            </Button>
          </DialogActions>
        </Dialog>

      </Grid>
    </div>
  );
}

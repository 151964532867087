import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function WhyPage() {
  return (
    <Box sx={{ flexGrow: 1, mt: '10px' }}>
      <Container>
        <Item sx={{
          boxShadow: 'none',
          textAlign: { sm: 'center', md: 'left' },
        }}
        >
          <div style={{ marginTop: '10px' }}>
            <div style={{
              position: 'absolute',
              marginTop: '3px',
              width: '8px',
              height: '8px',
              background: '#FA541C',
              borderRadius: '100%',
            }}
            />
            <h4 style={{
              color: '#919eab',
              textAlign: 'left',
              fontWeight: 400,
              fontSize: '13px',
              marginLeft: '15px',
            }}
            >
              РАССКАЗЫВАЕМ О САМОМ ВАЖНОМ
            </h4>
            <h1 style={{
              color: '#2f2f2f',
              //   textAlign: 'left',
              fontSize: '45px',
            }}
            >
              Зачем нужен мониторинг?

            </h1>
            <h4 style={{
              color: '#919eab',
              //   textAlign: 'left',
              fontWeight: 300,
              //   width: '70%',
              // fontStyle: 'italic',
              // fontSize: '13px',
            }}
            >
              В этом разделе вы можете ознакомиться с тем,
              какие преимущества вы получите используя
              <br />
              современные системы мониторинга транспорта
            </h4>
          </div>
        </Item>

        <Grid
          container
        //   spacing={2}
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            // mt: '100px',
          }}
        >
          <Grid xs={12} sm={6} md={4}>
            <Item sx={{
              boxShadow: 'none',
              minHeight: '440px',
              borderRight: { xs: 'none', sm: '1px solid #c9dbed', md: '1px solid #c9dbed' },
              marginBottom: '40px',
              marginTop: '40px',
              borderRadius: '0',
            }}
            >
              <img
                src="/icons/forWhyPage/speedometer.png"
                alt=""
                style={{
                  width: '90px',
                  position: 'absolute',
                  transform: 'translate(-55%, 100%)',
                  zIndex: '1000',
                }}
              />
              <h2 style={{
                fontSize: '100px',
                lineHeight: '0px',
                color: '#a05173',
                transform: 'scale(.8, 1.2) translate(17%)',
              }}
              >
                01

              </h2>
              <h2 style={{
                fontSize: '30px',
                color: '#2f2f2f',
                marginTop: '120px',
                transform: 'scale(.8, 1.2)',
              }}
              >
                КОНТРОЛЬ СКОРОСТИ

              </h2>
              <h3 style={{
                fontSize: '18px',
                fontWeight: '300',
                color: '#2f2f2f',
                // marginTop: '120px',
                transform: 'scale(.8, 1)',
              }}
              >
                Следите за скоростным режимом
                автотранспорта, получайте подробный отчет
                о превышении из системы мониторинга чтобы
                пресекать подобные нарушения
              </h3>
            </Item>
          </Grid>

          <Grid xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
            <hr style={{ border: '0.1px solid #c9dbed' }} />
          </Grid>

          <Grid xs={12} sm={6} md={4}>
            <Item sx={{
              boxShadow: 'none',
              minHeight: '440px',
              borderRight: { xs: 'none', sm: 'none', md: '1px solid #c9dbed' },
              marginBottom: '40px',
              marginTop: '40px',
              borderRadius: '0',
            }}
            >
              <img
                src="/icons/forWhyPage/monitoring-avto.png"
                alt=""
                style={{
                  width: '90px',
                  position: 'absolute',
                  transform: 'translate(-55%, 100%)',
                  zIndex: '1000',
                }}
              />
              <h2 style={{
                fontSize: '100px',
                lineHeight: '0px',
                color: '#a05173',
                transform: 'scale(.8, 1.2) translate(17%)',
              }}
              >
                02

              </h2>
              <h2 style={{
                fontSize: '30px',
                color: '#2f2f2f',
                marginTop: '120px',
                transform: 'scale(.8, 1.2)',
              }}
              >
                ГДЕ НАХОДИТСЯ АВТО?

              </h2>
              <h3 style={{
                fontSize: '18px',
                fontWeight: '300',
                color: '#2f2f2f',
                // marginTop: '120px',
                transform: 'scale(.8, 1)',
              }}
              >
                Отслеживайте местоположение автотранспорта
                в режиме онлайн с помощью системы мониторинга
                ГЛОНАСС/GPS и оперативно усправляйте логистикой
              </h3>
            </Item>
          </Grid>

          <Grid xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
            <hr style={{ border: '0.1px solid #c9dbed' }} />
          </Grid>

          <Grid xs={12} sm={6} md={4}>
            <Item sx={{
              boxShadow: 'none',
              minHeight: '440px',
              borderRight: { xs: 'none', sm: '1px solid #c9dbed', md: 'none' },
              marginBottom: '40px',
              marginTop: '40px',
              borderRadius: '0',
            }}
            >
              <img
                src="/icons/forWhyPage/zapravki-i-slivyi.png"
                alt=""
                style={{
                  width: '90px',
                  position: 'absolute',
                  transform: 'translate(-55%, 100%)',
                  zIndex: '1000',
                }}
              />
              <h2 style={{
                fontSize: '100px',
                lineHeight: '0px',
                color: '#a05173',
                transform: 'scale(.8, 1.2) translate(17%)',
              }}
              >
                03

              </h2>
              <h2 style={{
                fontSize: '30px',
                color: '#2f2f2f',
                marginTop: '120px',
                transform: 'scale(.8, 1.2)',
              }}
              >
                ЗАПРАВКИ И СЛИВЫ

              </h2>
              <h3 style={{
                fontSize: '18px',
                fontWeight: '300',
                color: '#2f2f2f',
                // marginTop: '120px',
                transform: 'scale(.8, 1)',
              }}
              >
                Возможность контролировать расход топлива
                для списания по фактическим показателям,
                а не средним нормам, пресечение
                сливов топлива с целью хищения
              </h3>
            </Item>
          </Grid>

          <Grid xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'block' } }}>
            <hr style={{ border: '0.1px solid #c9dbed' }} />
          </Grid>

          <Grid xs={12} sm={6} md={4}>
            <Item sx={{
              boxShadow: 'none',
              minHeight: '440px',
              borderRight: { xs: 'none', sm: 'none', md: '1px solid #c9dbed' },
              marginBottom: '40px',
              marginTop: '40px',
              borderRadius: '0',
            }}
            >
              <img
                src="/icons/forWhyPage/blokirovka-dvigatelya.png"
                alt=""
                style={{
                  width: '90px',
                  position: 'absolute',
                  transform: 'translate(-55%, 100%)',
                  zIndex: '1000',
                }}
              />
              <h2 style={{
                fontSize: '100px',
                lineHeight: '0px',
                color: '#a05173',
                transform: 'scale(.8, 1.2) translate(17%)',
              }}
              >
                04

              </h2>
              <h2 style={{
                fontSize: '30px',
                color: '#2f2f2f',
                marginTop: '120px',
                transform: 'scale(.8, 1.2)',
              }}
              >
                УДАЛЕННАЯ БЛОКИРОВКА

              </h2>
              <h3 style={{
                fontSize: '18px',
                fontWeight: '300',
                color: '#2f2f2f',
                // marginTop: '120px',
                transform: 'scale(.8, 1)',
              }}
              >
                Безопасная блокировка двигателя
                автотранспорта в случае угона или необходимости
                вернуть авто от недобросовестного водителя
              </h3>
            </Item>
          </Grid>

          <Grid xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
            <hr style={{ border: '0.1px solid #c9dbed' }} />
          </Grid>

          <Grid xs={12} sm={6} md={4}>
            <Item sx={{
              boxShadow: 'none',
              minHeight: '440px',
              borderRight: { xs: 'none', sm: '1px solid #c9dbed', md: '1px solid #c9dbed' },
              marginBottom: '40px',
              marginTop: '40px',
              borderRadius: '0',
            }}
            >
              <img
                src="/icons/forWhyPage/kilometrazh.png"
                alt=""
                style={{
                  width: '90px',
                  position: 'absolute',
                  transform: 'translate(-55%, 100%)',
                  zIndex: '1000',
                }}
              />
              <h2 style={{
                fontSize: '100px',
                lineHeight: '0px',
                color: '#a05173',
                transform: 'scale(.8, 1.2) translate(17%)',
              }}
              >
                05

              </h2>
              <h2 style={{
                fontSize: '30px',
                color: '#2f2f2f',
                marginTop: '120px',
                transform: 'scale(.8, 1.2)',
              }}
              >
                ПРОЙДЕННЫЙ КИЛОМЕТРАЖ

              </h2>
              <h3 style={{
                fontSize: '18px',
                fontWeight: '300',
                color: '#2f2f2f',
                // marginTop: '120px',
                transform: 'scale(.8, 1)',
              }}
              >
                Позволяет достоверно оценить амортизацию
                автотранспорта для своевремменного
                проходжения ТО и сервиса
              </h3>
            </Item>
          </Grid>

          <Grid xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
            <hr style={{ border: '0.1px solid #c9dbed' }} />
          </Grid>

          <Grid xs={12} sm={6} md={4}>
            <Item sx={{
              boxShadow: 'none',
              minHeight: '440px',
              borderRight: { xs: 'none', sm: 'none', md: 'none' },
              marginBottom: '40px',
              marginTop: '40px',
              borderRadius: '0',
            }}
            >
              <img
                src="/icons/forWhyPage/spectehnika.png"
                alt=""
                style={{
                  width: '90px',
                  position: 'absolute',
                  transform: 'translate(-55%, 100%)',
                  zIndex: '1000',
                }}
              />
              <h2 style={{
                fontSize: '100px',
                lineHeight: '0px',
                color: '#a05173',
                transform: 'scale(.8, 1.2) translate(17%)',
              }}
              >
                06

              </h2>
              <h2 style={{
                fontSize: '30px',
                color: '#2f2f2f',
                marginTop: '120px',
                transform: 'scale(.8, 1.2)',
              }}
              >
                КОНТРОЛЬ СПЕЦТЕХНИКИ

              </h2>
              <h3 style={{
                fontSize: '18px',
                fontWeight: '300',
                color: '#2f2f2f',
                // marginTop: '120px',
                transform: 'scale(.8, 1)',
              }}
              >
                Контролируйте часы работы техники,
                наблюдайте в реальном времени за её
                параметрами
              </h3>
            </Item>
          </Grid>

          {/* <Grid xs={4}>
            <Item>xs=4</Item>
          </Grid>
          <Grid xs={4}>
            <Item>xs=4</Item>
          </Grid>
          <Grid xs={4}>
            <Item>xs=4</Item>
          </Grid>
          <Grid xs={8}>
            <Item>xs=8</Item>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
}

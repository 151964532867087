import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function FAQAccordeon() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      {/* местоположение */}

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                Как отслеживается местоположение транспорта и других объектов?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <span>
                Для этого используются GPRS/ГЛОНАСС трекеры,
                передающие данные по местоположению в режиме реального времени.
                Они могут быть использованы для множества целей, таких как:

              </span>
              <h4 className="question-style">
                Отслеживание местонахождения транспортных средств:
              </h4>
              GPRS/ГЛОНАСС трекеры позволяют владельцам автомобилей или фирменных
              грузовиков отслеживать местонахождение транспорта в режиме реального
              времени, тем самым улучшая безопасность, эффективность движения и
              позволяя экономить на опережении графика.
              <span />
              <h4 className="question-style">
                Отслеживание расположения оборудования и инструментов:
              </h4>
              трекеры могут также быть использованы для отслеживания перемещения
              оборудования, которое используется на крупных производствах и
              стройплощадках, где могут быть тысячи различных устройств.
              <span>
                <span>
                  <h4 className="question-style">
                    Отслеживание местонахождения в случаях поиска и спасения:
                  </h4>
                  в экстремальных
                  ситуациях, таких как пожар или землетрясение, устройства определения
                  местонахождения могут быть эффективными инструментами для выявления
                  потерявшихся или заблудившихся людей.
                </span>
                <span>
                  <h4 className="question-style">
                    Отслеживание активности людей:
                  </h4>
                  трекеры могут быть использованы для
                  отслеживания перемещения и активности людей, например, для слежения за
                  своими детьми или пожилыми родственниками.
                </span>
                <span>
                  <h4 className="question-style">
                    Как GPRS/ГЛОНАСС трекеры определяют местоположение:
                  </h4>
                  они
                  используют системы спутниковой навигации, такие как GPS или ГЛОНАСС,
                  для получения координат местоположения. Трекеры также могут использовать
                  сотовую связь и WIFI соединения для передачи данных о местоположении на сервер.
                  Эти данные могут быть отображены на картографических онлайн-сервисах, в мобильных
                  приложениях или на специализированных сайтах, что позволяет пользователям
                  легко отслеживать местонахождение трекера.
                </span>
                <p />

              </span>
            </div>
          </div>
        </button>
      </div>

      {/* уровень топлива

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style">
                Как осуществляется конроль за уровнем топлива?
              </h3>
              <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              />
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <span>
                Для этого используются GPRS/ГЛОНАСС трекеры,
                передающие данные по местоположению в режиме реального времени.
                Они могут быть использованы для множества целей, таких как:

              </span>
              <h4 className="question-style">
                Отслеживание местонахождения транспортных средств:
              </h4>
              Датчики уровня топлива, такие как Omnicomm или Escort,
              работают на основе аналогичных принципов.
              Они обычно устанавливаются в бак для топлива и измеряют
              уровень топлива в нем с помощью токового датчика. Этот датчик
              подключен к контроллеру автомобиля и передает информацию о
              количестве топлива на панель приборов или к другому устройству
              для мониторинга топливопотребления.

              Датчик Omnicomm использует сенсор, который устанавливается внутри
              топливного бака. Этот сенсор
              измеряет уровень топлива с использованием метода ёмкостного измерения.
              Что означает, что датчик
              измеряет изменение электрической ёмкости между его плоскими поверхностями и
              топливом.
              Датчик Omnicomm также может измерять температуру топлива и скорость его расхода,
              что может помочь в обнаружении утечек и других проблем в работе системы топливоподачи.

              Датчик уровня топлива Escort также измеряет уровень топлива в баке путем
              использования своего лектронного модуля, который работает как
              магнитный датчик.
              Датчик Escort может быть установлен
              на любом типе топливного бака и может измерять уровень топлива с высокой точностью.

              Обе эти системы контроля уровня топлива могут быть полезны в обнаружении
              проблем с топливоподачей, таких как утечки топлива и потери топлива через испарение.
              Они также могут быть использованы для оптимизации расхода топлива и уменьшения
              затрат на топливо в долгосрочной перспективе.

            </div>
          </div>
        </button>
      </div> */}
    </Container>
  );
}

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function WhoWeAre() {
  return (
    <div id="o-nas" style={{ background: '#f7fbff' }}>
      <div style={{ height: '50px' }} />
      <Container sx={{ padding: '50px 25px 100px 10px' }}>
        <img
          src="./icons/forWhyPage/sistema-monitoringa-transporta.jpg"
          alt="система мониторинга транспорта"
          style={{
            border: '10px solid #f7fbff', borderRadius: '20px', display: 'block', width: '98%', margin: 'auto',
          }}
        />
        <Box sx={{ flexGrow: 1, mt: '30px' }}>
          <Grid container spacing={6}>
            <Grid xs={12} sm={12} md={6}>
              <Item sx={{
                boxShadow: 'none',
                background: '#f7fbff',
                textAlign: { xs: 'center', sm: 'center', md: 'right' },
              }}
              >
                <div>
                  {/* <div style={{
                  position: 'absolute',
                  marginTop: '3px',
                  width: '8px',
                  height: '8px',
                  background: '#FA541C',
                  borderRadius: '100%',
                }}
                /> */}
                  <h4 style={{
                    color: '#919eab',
                    // textAlign: 'right',
                    fontWeight: 400,
                    fontSize: '13px',
                    marginLeft: '15px',
                  }}
                  >
                    О НАС
                  </h4>
                  <h1 style={{
                    color: '#2f2f2f',
                    // textAlign: 'right',
                    fontSize: '45px',
                    //   lineHeight: '0px',
                  }}
                  >
                    Кто мы такие

                  </h1>
                  <h4 style={{
                    color: '#2f2f2f',
                    // textAlign: 'right',
                    fontWeight: 300,
                  //   width: '70%',
                  // fontStyle: 'italic',
                  // fontSize: '13px',
                  }}
                  >
                    Компания Геотэг существует на рынке мониторинга транспорта около 10 лет.
                    Наши специалисты имеют опыт от 3х до 15ти лет в области
                    gps и глонасс мониторинга пассажирского и грузового автотранспорта.
                    <p />
                    У нас вы можете купить и установить gps или глонасс трекер для спутникового
                    отслеживания ТС и подключиться к одной из существующих платформ для
                    онлайн мониторинга транспорта.
                    <p />
                    С помощью системы спутникового мониторинга вы всегда можете отследить
                    свою машину или другой объект и получить отчет по его перемещениям и
                    другим действиям, используя gps маячок и другие датчики.
                  </h4>
                  <br />
                  <div>
                    <a
                      style={{
                        textDecoration: 'none',
                        color: '#FA541C',
                        fontSize: '16px',
                      }}
                      href="#contacts"
                    >
                      Свяжитесь с нами
                      {' '}
                      <span style={{ fontSize: '20px' }}>→</span>
                    </a>
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid xs={12} sm={12} md={6}>

              <Item sx={{
                display: 'flex', alignItems: 'center', boxShadow: 'none', marginTop: '20px', background: '#f7fbff',
              }}
              >
                <Grid
                  xs={3}
                  sm={3}
                  md={3}
                  sx={{
                    padding: { xs: '0 0px 0 0', sm: '0 20px 0 0', md: '0 0px 0 0px' },
                  }}
                >
                  <Item sx={{
                    boxShadow: 'none',
                    marginTop: '25px',
                    background: '#f7fbff',
                  }}
                  >
                    <h1 style={{
                      color: '#2f2f2f',
                      textAlign: 'center',
                      fontSize: '45px',
                      lineHeight: '0px',
                      // marginLeft: '25px',
                      marginTop: '0px',
                    }}
                    >
                      5к
                      <span style={{ color: '#FA541C', fontSize: '25px', verticalAlign: '30px' }}>+</span>
                    </h1>
                    <h4 style={{
                      color: '#919eab',
                      textAlign: 'center',
                      fontWeight: 400,
                      fontSize: '13px',
                      marginRight: '12px',
                    }}
                    >
                      ОБЪЕКТОВ
                    </h4>
                  </Item>
                </Grid>
                <Grid xs={9} sm={9} md={9}>
                  <Item sx={{
                    textAlign: 'left',
                    background: '#f7fbff',
                    //   paddingTop: '25px',
                    paddingLeft: '30px',
                    //   height: '60px',
                    //   marginBottom: '15px',
                    boxShadow: 'none',
                    borderLeft: '1px solid #c9dbed',
                    borderRadius: '0',
                  }}
                  >
                    В нашей компании обслуживается свыше 5000 объектов
                    пассажирского и грузового транспорта

                  </Item>
                </Grid>
              </Item>
              <Item sx={{
                display: 'flex', alignItems: 'center', boxShadow: 'none', marginTop: '20px', background: '#f7fbff',
              }}
              >
                <Grid
                  xs={3}
                  sm={3}
                  md={3}
                  sx={{
                    padding: { xs: '0 0px 0 0', sm: '0 20px 0 0', md: '0 0px 0 0px' },
                  }}
                >
                  <Item sx={{
                    boxShadow: 'none',
                    marginTop: '25px',
                    background: '#f7fbff',
                  }}
                  >
                    <h1 style={{
                      color: '#2f2f2f',
                      textAlign: 'center',
                      fontSize: '45px',
                      lineHeight: '0px',
                      // marginLeft: '25px',
                      marginTop: '0px',
                    }}
                    >
                      10
                      <span style={{ color: '#FA541C', fontSize: '25px', verticalAlign: '30px' }}>+</span>
                    </h1>
                    <h4 style={{
                      color: '#919eab',
                      textAlign: 'center',
                      fontWeight: 400,
                      fontSize: '13px',
                      marginRight: '12px',
                    }}
                    >
                      ЛЕТ
                    </h4>
                  </Item>
                </Grid>
                <Grid xs={9} sm={9} md={9}>
                  <Item sx={{
                    textAlign: 'left',
                    background: '#f7fbff',
                    //   paddingTop: '25px',
                    paddingLeft: '30px',
                    //   height: '60px',
                    //   marginBottom: '15px',
                    boxShadow: 'none',
                    borderLeft: '1px solid #c9dbed',
                    borderRadius: '0',
                  }}
                  >
                    Вот уже более 10 лет мы занимаемся мониторингом
                    транспорта как в РФ, так и за её пределами

                  </Item>
                </Grid>
              </Item>

              <Item sx={{
                display: 'flex', alignItems: 'center', boxShadow: 'none', marginTop: '20px', background: '#f7fbff',
              }}
              >
                <Grid
                  xs={3}
                  sm={3}
                  md={3}
                  sx={{
                    padding: { xs: '0 0px 0 0', sm: '0 20px 0 0', md: '0 0px 0 0' },
                  }}
                >
                  <Item sx={{
                    boxShadow: 'none',
                    marginTop: '25px',
                    background: '#f7fbff',
                  }}
                  >
                    <h1 style={{
                      color: '#2f2f2f',
                      textAlign: 'center',
                      fontSize: '45px',
                      lineHeight: '0px',
                      marginTop: '0px',
                    }}
                    >
                      150
                      <span style={{ color: '#FA541C', fontSize: '25px', verticalAlign: '30px' }}>+</span>
                    </h1>
                    <h4 style={{
                      color: '#919eab',
                      textAlign: 'center',
                      fontWeight: 400,
                      fontSize: '13px',
                      marginRight: '1px',
                    }}
                    >
                      ПРОЕКТОВ
                    </h4>
                  </Item>
                </Grid>
                <Grid xs={9} sm={9} md={9}>
                  <Item sx={{
                    textAlign: 'left',
                    background: '#f7fbff',
                    //   paddingTop: '25px',
                    paddingLeft: '30px',
                    //   height: '60px',
                    //   marginBottom: '15px',
                    boxShadow: 'none',
                    borderLeft: '1px solid #c9dbed',
                    borderRadius: '0',
                  }}
                  >
                    Нашими специалистами
                    создано более 150 уникальных решений
                    под нестандартные задачи наших заказчиков

                  </Item>
                </Grid>
              </Item>

              {/* <Item>xs=4</Item>
            <Item>xs=4</Item> */}
            </Grid>
            {/* <Grid xs={12} sm={6} md={6}>
            <Item>xs=4</Item>
          </Grid>
          <Grid xs={12} sm={6} md={6}>
            <Item>xs=8</Item>
          </Grid> */}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { SvgIcon } from '@mui/material';
import AdbIcon from '@mui/icons-material/Adb';
import SMM from '../SMM/SMM';

// const pages = ['Оборудование и услуги', 'О нас', 'Контакты'];
const pages = [
  {
    name: 'Расчет',
    link: '#raschet-stoimosti',
  },
  {
    name: 'Faq',
    link: '#faq-link',
  },
  {
    name: 'Пакеты услуг',
    link: '#oborudovanie-i-uslugi',
  },
  {
    name: 'Конструктор',
    link: '#monitoring-konstruktor',
  },
  {
    name: 'О нас',
    link: '#o-nas',
  },
  // {
  //   name: 'Контакты',
  //   link: '#contacts',
  // },
];

// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div id="navbar">
      <AppBar position="fixed" style={{ backgroundColor: '#f0682e', minHeight: '75px', boxShadow: 'none' }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>

            <Avatar
              alt="Геотэг"
              src=""
              sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
              style={{
                height: '70px', width: '60px', background: 'none', margin: 'auto',
              }}
            >
              <img src="/icons/logoGeotagPng.png" alt="Геотэг" style={{ height: '65px' }} />
            </Avatar>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#navbar"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'block' },
                fontFamily: 'Roboto',
                fontWeight: 700,
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
                paddingTop: '20px',
                lineHeight: '1px',
                textAlign: 'center',
              }}
            >
              ГЕОТЭГ
              <hr style={{ color: 'white' }} />
              <p style={{
                fontSize: '14px',
                fontWeight: '200',
                letterSpacing: 1,
                marginTop: 0,
              }}
              >
                мониторинг
              </p>
              <p style={{ fontSize: '14px', fontWeight: '200', letterSpacing: 1 }}>транспорта</p>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  // display: { xs: 'block', md: 'none' },
                }}
              >

                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    <a
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        color: 'black',
                        textDecoration: 'none',
                        textTransform: 'uppercase',
                        fontWeight: '300',
                      }}
                      href={page.link}
                    >
                      {page.name}

                    </a>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
            <Avatar
              alt="Geotag"
              src=""
              sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
              style={{
                height: '70px',
                width: '40px',
                background: 'none',
                margin: 'auto',
                marginLeft: '33px',
              }}
            >
              <img src="/icons/logoGeotagPng.png" alt="logo-Geotag" style={{ height: '35px' }} />
            </Avatar>
            {/* <img
          src="/icons/logoGeotagPng.png"
          alt=""
          style={{ height: '30px', marginRight: '7px', display: { xs: 'flex', md: 'none' } }} /> */}
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 4,
                display: { xs: 'block', md: 'none' },
                flexGrow: 1,
                fontFamily: 'Roboto',
                fontWeight: 700,
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
                paddingTop: '4px',
                lineHeight: '20px',
              }}
            >
              ГЕОТЭГ
            </Typography>
            <Box sx={{ display: { xs: 'flex', md: 'none' }, marginTop: '6px', marginRight: '3px' }}>
              <SMM />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'center' } }}>

              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  <a
                    className="navMenu"
                    style={{
                      color: 'white', textDecoration: 'none', fontWeight: '300', marginRight: '10px',
                    }}
                    href={page.link}
                  >
                    {page.name}

                  </a>
                  {/* <Link
                  style={{
                    color: 'white', textDecoration: 'none', fontWeight: '300', marginRight: '40px',
                  }}
                  to={page.link}
                >
                  {page.name}
                </Link> */}
                </Button>
              ))}
            </Box>

            <div
              className="menuWithSmm"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="tel:+74993917374"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'block' },
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                  fontSize: '15px',
                  letterSpacing: '.04rem',
                  color: 'inherit',
                  textDecoration: 'none',
                  padding: '3px 0 7px 0',
                  lineHeight: '15px',
                  textAlign: 'right',
                }}
              >
                +7 499 391 73 74
              </Typography>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, paddingRight: '15px' }}>
                <SMM />
              </Box>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
export default ResponsiveAppBar;

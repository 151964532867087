import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function HiddenTracker() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      {/* местоположение */}

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                Что такое скрытый gps маячок и зачем он нужен?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <h4 className="question-style">
                Скрытый GPS/ГЛОНАСС-маяк, представляет собой небольшое устройство,
                часто размером с банковскую карту, которое можно спрятать внутри автомобиля,
                чтобы отслеживать его местоположение в режиме реального времени.
              </h4>
              <span>
                {' '}
                Он использует
                систему Global Positioning System - GPS или ГЛОНАСС, чтобы определять местоположение
                автомобиля и передавать эти данные на сервер. Можно получать информацию о скорости,
                направлении движения, времени и местоположении автомобиля.
                <p />
                Такие устройства позволяют владельцам автомобилей отслеживать свои
                транспортные средства, а также могут быть использованы
                для предотвращения кражи и улучшения
                безопасности вождения. Они могут оповещать владельцев автомобилей об изменениях
                местоположения своих транспортных средств, а также зафиксировать их местоположение
                в случае угона.
                <p />
                Кроме того, скрытые GPS-маяки могут быть использованы в коммерческих целях,
                чтобы отслеживать грузовики и другое транспортное оборудование в режиме реального
                времени. Это может помочь в улучшении управления логистикой и повышении
                эффективности бизнес-процессов.
                <p />
                Важно отметить, что использование скрытых GPS-маяков может вызвать определенные
                этические вопросы в отношении частной жизни и прав на конфиденциальность.
                Поэтому перед установкой такого устройства следует убедиться, что это допустимо
                и защищено законом в соответствующей юрисдикции.
              </span>
              <p />
            </div>
          </div>
        </button>
      </div>
    </Container>
  );
}

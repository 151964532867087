import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function BlockEngine() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                Как осуществляется удаленная блокировка двигателя в автомобиле?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <h4 className="question-style">
                Одним из наиболее распространенных методов является использование системы
                GPS-мониторинга и управления автопарком в совокупности со специальными реле.
              </h4>
              <span>
                GPS-мониторинг обычно осуществляется с помощью установленного на автомобиль
                трекера, который может давать данные о местоположении, скорости и
                других характеристиках движения транспортного средства. Если устройство
                обнаруживает, что автомобиль движется без правильного разрешения или
                находится в ненадлежащем месте, то специально настроенная система или
                оператор могут выдать команду на
                удаленную блокировку двигателя.
                <p />
                В зависимости от настроек системы, блокировка двигателя может происходить
                путем размыкания контактов зажигания, перекрытия топливного потока или
                другими способами. Это поможет предотвратить дальнейшее движение автомобиля
                и помочь в защите от угона или несанкционированного использования.
                <p />
                Удаленная блокировка двигателя может быть использована компаниями по
                логистике для управления своим автопарком, позволяя ограничить доступ
                к несанкционированным водителям или защищать автомобили от краж и
                других видов незаконного использования.
              </span>
              <p />
            </div>
          </div>
        </button>
      </div>
    </Container>
  );
}

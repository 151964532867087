import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function Angle() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      {/* контроль вождения */}

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                Зачем нужен датчик угла наклона?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <h4 className="question-style">
                Датчик угла наклона используется для
                измерения угла наклона или наклона поверхности, на которой он установлен.
              </h4>
              <span>
                Такой датчик может быть полезен во многих приложениях, таких как автоматизированные
                системы контроля качества производства и обнаружения опасных ситуаций в
                различных сферах деятельности.
                <p />
                В автомобильной промышленности датчик угла наклона может использоваться для
                обнаружения изменений поведения транспортного средства и определения того,
                подверглось ли оно потенциальному риску аварии или сбоя. Датчик угла наклона
                можно использовать также для автоматической корректировки системы стабилизации
                автомобиля во время движения.
                <p />
                Датчик угла наклона может использоваться и в других отраслях, например, в науке и
                технологиях, для измерения наклона лабораторных приборов и оборудования, в
                аэрокосмической промышленности, чтобы контролировать ориентацию и положение
                спутников, ракет и других аппаратов во время полета.
                <p />
                Таким образом, датчик угла наклона, такой как например, ESCORT Duo BLE, может быть
                использован во множестве областей для измерения и контроля угла наклона
                или наклона поверхности, что позволяет повышать эффективность процессов, повышать
                безопасность и обнаруживать опасные ситуации.
              </span>
              <p />
            </div>
          </div>
        </button>
      </div>
    </Container>
  );
}

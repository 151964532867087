import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function RNIS() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      {/* контроль вождения */}

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                Что такое РНИС и зачем подключать к нему автомобиль?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <h4 className="question-style">
                РНИС (Российская навигационно-информационная система) - это
                государственная система, которая включает в себя спутниковую навигацию
                (ГЛОНАСС), управление транспортом и автоматизацию дорожного движения.
              </h4>
              <span>
                Она была создана с целью повышения эффективности использования
                транспортных средств и улучшения безопасности на дорогах.
                <p />
                <h4>
                  С 5 мая 2021 года вступили в силу новые правила
                  передвижения по Москве, которые касаются как жителей столицы,
                  так и тех, кто приезжает из регионов или международных перевозчиков.
                  Для транспорта весом свыше 3,5 тонн требуется соблюдать правительственные
                  требования по транспортировке грузов и пассажиров, такие как передача
                  данных о координатах машин в общую информационную базу.
                  Для этого нужно установить трекеры на автомобили и зарегистрироваться в РНИС.
                </h4>

                Те, кто въезжает на МКАД и далее - в Москву,
                должны получать дневные и ночные пропуска в зависимости от времени
                посещения столицы. Те перевозчики, кто уже использовал телематическое
                оборудование, могут продолжать так делать, но новые организации и
                владельцы транспорта должны заранее запастись соответствующим оборудованием,
                терминальным устройством и разрешениями на проезд в Москву.
                <br />
                Без этого проезд в столицу будет запрещен, а пропуски будут выдаваться только
                тем, кто передает информацию в РНИС.
                <p />
                Для того, чтобы автомобиль мог быть подключен к РНИС, ему необходимо
                установить специальный навигационный приемник - GPS/ГЛОНАСС трекер. Этот приемник
                может получать сигналы от спутников глобальной навигационной системы и
                передавать информацию о расположении автомобиля на серверы РНИС.
                <p />
                Благодаря этому владелец автомобиля также может отслеживать его местонахождение,
                скорость движения и другие параметры.
              </span>
              <p />
            </div>
          </div>
        </button>
      </div>
    </Container>
  );
}

import { useEffect, useState } from 'react';
// import { read, utils } from 'xlsx';
import { useDispatch } from 'react-redux';
import { getData, fetchTable } from '../../store/dataSlice';

const url = 'adminTable.xlsx';

export default function DataLinkContext() {
  const dispatch = useDispatch();
  // const [dataGpsTable, setDataGpsTable] = useState();

  // вызовем здесь с помощью диспатча наш экшн
  useEffect(() => {
    dispatch(fetchTable());
    // console.log('EFFECT!');
  }, [dispatch]);

  // const myData = () => {
  //   dispatch(getData({ dataGpsTable }));
  //   console.log(typeof (dataGpsTable));
  //   console.log(dataGpsTable);
  // };

  // if (typeof (dataGpsTable) === 'object') {
  //   console.log(dataGpsTable);
  //   myData();
  //   // console.log(state, 'это стэйт который нужен');
  // }

  // useEffect(() => {
  //   (async () => {
  //     const wb = read(await (await fetch(url)).arrayBuffer(), { WTF: 1 });

  //     wb.SheetNames.forEach((i) => {
  //       const data = utils.sheet_to_json(wb.Sheets[i], { header: 1 });
  //       setDataGpsTable((prev) => ({
  //         ...prev,
  //         [i]: data.map((el) => (
  //           {
  //             Name: el[0],
  //             Text: el[1],
  //             Price: el[2],
  //             Image: el[3],
  //           }
  //         )),
  //       }));
  //     });
  //     // myData();
  //   })();
  // }, []);

  // if (typeof (dataGpsTable) === 'object') {
  return (
    <>
      {/* <div>DataLinkFinal</div> */}
      {/* {1 + 1}
        {dataGpsTable.map((row) => (
          <div key={row.Name}>
            <h3>{row.Name}</h3>
          </div>
        ))} */}
    </>
  );
  // }
}

import React from 'react';

export default function SMM() {
  return (
    <div
      className="smmMobile"
    >
      <a style={{ height: '40px', marginRight: '10px' }} target="_blank" href="https://wa.me/+79253661514" rel="noreferrer">
        <svg
          viewBox="0 0 24 24"
          fill="white"
          height="30"
          width="30"
          id="whatsapp"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M 11.958 2.057 L 11.954 2.057 C 6.367 2.057 1.827
                    6.529 1.827 12.029 C 1.824 14.13 2.498 16.179 3.755 17.876
                    L 2.492 21.582 L 6.383 20.355 C 8.036 21.434 9.977 22.007
                    11.958 22.002 C 17.542 22.002 22.084 17.528 22.084 12.029 C
                    22.084 6.531 17.542 2.057 11.958 2.057 Z M 17.853 16.14 C
                    17.606 16.82 16.636 17.383 15.864 17.549 C 15.335 17.659 14.643
                    17.746 12.317 16.794 C 9.339 15.583 7.422 12.605 7.273 12.413
                    C 7.13 12.218 6.07 10.835 6.07 9.403 C 6.07 7.972 6.81 7.276
                    7.106 6.974 C 7.351 6.731 7.754 6.62 8.142 6.62 C 8.267 6.62
                    8.378 6.625 8.481 6.632 C 8.776 6.645 8.931 6.659 9.122 7.122
                    C 9.368 7.703 9.963 9.132 10.035 9.279 C 10.107 9.428 10.179
                    9.628 10.077 9.821 C 9.984 10.018 9.899 10.106 9.749 10.277 C
                    9.601 10.446 9.461 10.576 9.311 10.757 C 9.173 10.917 9.018
                    11.087 9.189 11.377 C 9.363 11.665 9.958 12.622 10.835 13.389
                    C 11.964 14.381 12.882 14.699 13.21 14.833 C 13.452 14.931
                    13.745 14.91 13.923 14.72 C 14.151 14.481 14.428 14.082 14.713
                    13.689 C 14.917 13.408 15.172 13.373 15.44 13.472 C 15.714 13.566
                    17.163 14.268 17.461 14.416 C 17.758 14.562 17.952 14.633 18.025
                    14.759 C 18.097 14.881 18.097 15.461 17.853 16.14 Z"
          />
        </svg>
      </a>
      <a style={{ height: '40px' }} target="_blank" href="https://t.me/+79253661514" rel="noreferrer">
        <svg
          viewBox="0 0 24 24"
          fill="white"
          height="30"
          width="30"
          id="telegram"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M11.99432,2a10,10,0,1,0,10,10A9.99917,9.99917,0,0,0,11.99432,
                    2Zm3.17951,15.15247a.70547.70547,0,0,1-1.002.3515l-2.71467-2.10938L9.71484,17.002a.29969.29969,0,0,1-.285.03894l.334-2.98846.01069.00848.00683-.059s4.885-4.44751,5.084-4.637c.20147-.189.135-.23.135-.23.01147-.23053-.36152,0-.36152,0L8.16632,13.299l-2.69549-.918s-.414-.1485-.453-.475c-.041-.324.46649-.5.46649-.5l10.717-4.25751s.881-.39252.881.25751Z"
          />
        </svg>
      </a>
    </div>
  );
}

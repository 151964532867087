import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { Button, MenuItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Select from '@mui/material/Select';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

import CardForCalcNew from './CardForCalc/CardForCalcNew';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function SelectCalcComponent({
  dbSelect,
  selValue,
  handleOnChange,
  selLabel,
  selectName,
  imgSrc,
}) {
  const [openSel, setOpenSel] = useState(false);

  const searchInput = useRef(null);

  function handleFocus() {
    searchInput.current.blur(); // removing focus
  }

  // const searchInput = useRef(null);
  // function handleFocus() {
  //   searchInput.current.blur(); // removing focus
  // }
  // const handleOpenSel = () => setOpenSel(true);
  // const handleCloseSel = () => setOpenSel(false);

  // console.log(openSel);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <img
        style={{
          marginTop: '20px',
          height: '35px',
          minWidth: '35px',
          borderRight: '8px solid white',
        }}
        src={imgSrc}
        alt=""
      />
      {/* <Box sx={{ minWidth: '90%' }} /> */}
      <FormControl
        variant="standard"
        sx={{
          m: 1,
          // minWidth: '87vw',
          width: '100%',
        }}
      >
        <InputLabel id="demo-simple-select-standard-label">
          {selLabel}
        </InputLabel>
        <Select
          className="inputForCalcSelect"
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name={selectName}
          value={selValue}
          onChange={handleOnChange}
          variant="standard"
          // меняет состояние списка при открытом на true и наоборот
          onFocus={() => {
            setOpenSel(!openSel);
            // console.log(openSel);
            // console.log('focus!');
          }}
          // чтобы при выборе снималось выделение автоматом
          onClose={() => {
            setTimeout(() => {
              document.activeElement.blur();
            }, 0);
          }}
          select="true"
        >
          {dbSelect.map((el, i) => (
            <MenuItem
              key={el.Name}
              value={el}
              className="selectElement"
              // style={{ display: 'block' }}
            >
              {!openSel || i === 0 ? (
                // el.Name
                <span style={{
                  width: '90vw',
                  // height: '50px',
                  margin: 0,
                  textAlign: 'center',
                }}
                >
                  {el.Name}
                </span>
              ) : (
                <CardForCalcNew
                  // onClick={handleOnChange}
                  elname={el.Name}
                  eltext={el.Text}
                  elimage={el.Image}
                  elprice={el.Price}
                />
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Parallax, Background } from 'react-parallax';
import Grid from '@mui/material/Grid';

import { useSelector } from 'react-redux';

export default function FirstPage() {
  const dataGpsTableMy = useSelector((state) => state.datas);
  // console.log(dataGpsTableMy.datas.abService[3].Price);
  if (dataGpsTableMy.datas.length !== 0) {
    return (
      <div
        style={{
          minHeight: '700px', backgroundColor: '#f0682e',
        }}
      >
        <Container maxWidth="lg">
          <Parallax
            className="parallaxImg"
            style={{
              position: 'absolute',
              paddingTop: '270px',
              // transform: 'translateX(50vw)',
              // left: '70px',
              // right: '70px',
              height: '400px',
              width: '600px',
              zIndex: 1,
            }}
          //   blur={{ min: -20, max: 20 }}
            // bgImage="pictures/dogNew.png"
            bgImage="pictures/district.png"
            bgImageAlt="мониторинг транспорта"
            strength={-200}
          >
            {/* <div style={{ minHeight: '100%' }} /> */}
            {/* Content goes here. Parallax height grows with content height. */}
          </Parallax>
          <div style={{ paddingTop: '100px', position: 'relative', zIndex: '1000' }}>
            <Grid
              sx={{
                mr: 2,
                // mt: -8,
                display: { xs: 'flex', md: 'block' },
                flexWrap: 'wrap',
                fontFamily: 'Roboto',
                fontWeight: 700,
                fontSize: '50px',
                letterSpacing: '.1rem',
                color: 'white',
                textDecoration: 'none',
                justifyContent: { xs: 'space-around' },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              <h1 style={{
                marginRight: 2,
                marginBottom: '0px',
                flexWrap: 'wrap',
                fontFamily: 'Roboto',
                fontWeight: 700,
                fontSize: '50px',
                letterSpacing: '.1rem',
                color: 'white',
                textDecoration: 'none',
              }}
              >
                GPS/ГЛОНАСС
                <p style={{ margin: 0 }}>мониторинг транспорта</p>
                и других объектов
              </h1>
              <h2 style={{
                fontSize: '21px',
                fontWeight: '300',
                letterSpacing: 1,
                marginTop: '70px',
              }}
              >
                <p style={{ margin: 0 }}>ОБОРУДОВАНИЕ И ПОДКЛЮЧЕНИЕ К СИСТЕМЕ</p>
                {' '}
                <p style={{ margin: 0 }}>МОНИТОРИНГА ТРАНСПОРТА</p>
                АБОНЕНТСКОЕ ОБСЛУЖИВАНИЕ ОТ
                {' '}
                {dataGpsTableMy.datas.abService[3].Price / 12}
                р/месяц
              </h2>
            </Grid>

            {/* <Typography
              variant="h1"
              component="a"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'block' },
                flexWrap: 'wrap',
                fontFamily: 'Roboto',
                fontWeight: 700,
                fontSize: '50px',
                //   fontSize: { xs: '40px', md: '50px' },
                letterSpacing: '.1rem',
                color: 'white',
                textDecoration: 'none',
                justifyContent: { xs: 'space-around' },
                //   paddingTop: '20px',
                //   lineHeight: '60px',
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              GPS/ГЛОНАСС
              <p style={{ margin: 0 }}>мониторинг транспорта</p>
              и других объектов
              <h2 style={{
                fontSize: '21px',
                fontWeight: '300',
                letterSpacing: 1,
                marginTop: '70px',
              }}
              >
                АБОНЕНТСКОЕ ОБСЛУЖИВАНИЕ ОТ
                {' '}
                {dataGpsTableMy.datas.abService[3].Price / 12}
                р/месяц
                <p style={{ margin: 0 }}>УСТАНОВКА ТРЕКЕРОВ И ПОМОЩЬ В РАБОТЕ С СИСТЕМОЙ</p>
                {' '}
                <p style={{ margin: 0 }}>МОНИТОРИНГА ТРАНСПОРТА</p>
              </h2>
            </Typography> */}
            <Stack sx={{ justifyContent: { xs: 'center', md: 'start' } }} className="firstButtonGroup" spacing={3} direction="row">
              <Button href="#oborudovanie-i-uslugi" className="firstButton" variant="outlined">Пакеты услуг</Button>
              <Button
                href="#monitoring-konstruktor"
                className="firstButton"
                variant="outlined"
              >
                Оборудование

              </Button>
            </Stack>
          </div>
        </Container>
      </div>
    );
  }
}

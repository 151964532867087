import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function CardForCalcNew({
  elname, eltext, elimage, elprice,
}) {
  return (
    <Card sx={{
      maxWidth: 345,
      minHeight: 610,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'start',
      padding: '0 10px 10px 10px',
    }}
    >
      <img
        src={elimage}
        style={{ maxHeight: '250px', maxWidth: '250px' }}
        alt={elname}
      />

      <h5 style={{ fontSize: '20px', fontWeight: '400', whiteSpace: 'normal' }}>
        {elname}
        {/* {' '}
        (
        {elprice}
        {' '}
        руб) */}
      </h5>

      <div
        style={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          textAlign: 'left',
        }}
      >
        {eltext}
      </div>

    </Card>
  );
}
